import format from "date-fns/format";
import { Service } from "../features/common/commonSlice";

const formatDateStandar = (dateString?: string) => {
  if (!dateString) return "Sin Fecha";
  try {
    let date = new Date(dateString);
    if (date) {
      let formatedDate = format(date, "dd/MM/yyyy");
      let formatedTime = format(date, "hh:mm a");
      return `${formatedDate} | ${formatedTime}`;
    } else {
      return "Sin Fecha";
    }
  } catch {
    return "Sin Fecha";
  }
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getServiceName = (name: Service) => {
  switch (name) {
    case "coreId":
      return "Core ID";
    case "firmaNew":
      return "Firma Electrónica";
    case "magicForms":
      return "Magic Forms";
    case "hyperFlow":
      return "HyperFlow";
    case "smartFlow":
      return "Smart Flow";
    case "pagares":
      return "Pagarés";
    case "authentikator":
      return "Authentikator";
    case "dashboard":
      return "Dashboard";
    default:
      return name;
  }
};

const getServicePath = (name: Service) => {
  switch (name) {
    case "coreId":
      return "/services/coreId/registrations";
    case "firmaNew":
      return "/services/electronicSignatureNew/signatures";
    case "magicForms":
      return "/services/magicForms/filledForms";
    case "hyperFlow":
      return "/services/hyperFlow/flows";
    case "smartFlow":
      return "/services/smartFlow/flows";
    case "pagares":
      return "/services/pagares/list";
    case "authentikator":
      return "/services/authentikator/processes";
    case "dashboard":
      return "/services/dashboardClient/indicators";
    default:
      return "";
  }
};

const getStatusFlow = (name: string, acceptanceStatus: string) => {
  let acceptanceStatusTxt = "";
  if (acceptanceStatus === "ACCEPTED") acceptanceStatusTxt = ": Aceptado";
  if (acceptanceStatus === "REJECTED") acceptanceStatusTxt = ": Negado";
  switch (name) {
    case "IN_PROGRESS":
      return "En proceso";
    case "DONE":
      return "Completado" + acceptanceStatusTxt;
    case "AWAITING_MANUAL_INTERACTION":
      return "En revisión";
    case "AWAITING_USER_INTERACTION":
      return "En espera usuario";
    case "ABANDONED":
      return "Desistido";
    default:
      return name;
  }
};

const getServicePathData = (name: string) => {
  switch (name) {
    case "Core ID":
      return "/services/coreId/registrations";
    case "Adjuntos":
      return "/services/electronicSignature/signatures";
    case "Firma":
      return "/services/electronicSignatureNew/signatures";
    case "Firma Electrónica":
      return "/services/electronicSignatureNew/signatures";
    case "Magic Forms":
      return "/services/magicForms/filledForms";
    case "HyperFlow":
      return "/services/hyperFlow/flows";
    case "Smart Flow":
      return "/services/smartFlow/flows";
    case "Authentikator":
      return "/services/authentikator/processes";
    case "Dashboard":
      return "/services/dashboardClient/indicators";
    default:
      return "";
  }
};

const base64PDFToBlobUrl = (base64: string) => {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  const blob = new Blob([arr], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  return url;
};

const currencyFormat = (num: number) => {
  return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const unFormatCurrency = (num: string) => {
  return num.replace(/[^0-9.-]+/g, "");
};

const useQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

const getBrowser = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Chrome") > -1) return "Chrome";
  if (userAgent.indexOf("Safari") > -1) return "Safari";
  if (userAgent.indexOf("Opera") > -1) return "Opera";
  if (userAgent.indexOf("Firefox") > -1) return "Firefox";
  if (userAgent.indexOf("MSIE") > -1) return "IE";
  return "unknown";
};

const getMimeTypeBasedOnExtension = (extension: string) => {
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "jpg":
      return "image/jpeg";
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "txt":
      return "text/plain";
    case "csv":
      return "text/csv";
    default:
      return "application/octet-stream";
  }
};

export {
  formatDateStandar,
  getServiceName,
  getServicePath,
  getServicePathData,
  getStatusFlow,
  base64PDFToBlobUrl,
  currencyFormat,
  unFormatCurrency,
  useQueryParams,
  getBrowser,
  getMimeTypeBasedOnExtension,
};
