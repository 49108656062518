import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./introStyles.scss";
import "./introdStyles.scss";

import {
  changeActiveComponent,
  selectActiveCoreIdComponent,
} from "../services/coreid/coreIdSlice";
import { emptyTokenString, selectTokenString } from "../login/loginSlice";
import {
  changeCurrentProduct,
  getClientAndUserData,
  selectActiveServices,
  selectClientName,
  selectInactiveServices,
  selectLoading,
  selectRoles,
  Service,
} from "../common/commonSlice";
import { getServiceName, getServicePath } from "../../helpers/utils";
import { ListService } from "./components/ListService";
import { TopPanel } from "../services/components/TopPanel";
import { SidePanel } from "../services/components/sidePanel/SidePanel";
import { Category } from "../services/components/sidePanel/categories/Category";
import { CategoriesServicesComponent } from "../services/components/sidePanel/categories/CategoriesServicesComponent";

export const Introv2 = () => {
  //Selectors
  let tokenString = useAppSelector(selectTokenString);
  let loading = useAppSelector(selectLoading);
  let activeServices = useAppSelector(selectActiveServices);
  let inactiveServices = useAppSelector(selectInactiveServices);
  let clientName = useAppSelector(selectClientName);
  let roles = useAppSelector(selectRoles);
  const [selectedService, setSelectedService] = useState<Service>("");
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);

  // Dispatcher
  let dispatch = useAppDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      dispatch(emptyTokenString());
    }
    if (activeServices.length === 0 && inactiveServices.length === 0) {
      dispatch(getClientAndUserData());
    }
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("Registration"));
    }
    dispatch(changeCurrentProduct(""));
  });

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
      }}
    >
      <TopPanel />

      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          categories={
            <CategoriesServicesComponent
              categories={activeServices.map((service, index) => {
                if (service == "pagares") {
                  if (roles.includes("pagare.view")) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service == "coreId") {
                  if (
                    roles.includes("coreid.view") ||
                    roles.includes("coreid.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service == "hyperFlow") {
                  if (
                    roles.includes("hyperflow.view") ||
                    roles.includes("hyperflow.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service == "magicForms") {
                  if (
                    roles.includes("magicforms.view") ||
                    roles.includes("magicforms.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service === "firmaNew") {
                  if (
                    roles.includes("firma.view") ||
                    roles.includes("firma.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service == "authentikator") {
                  if (
                    roles.includes("authentikator.view") ||
                    roles.includes("authentikator.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else if (service === "dashboard") {
                  if (
                    roles.includes("dashboard.view") ||
                    roles.includes("dashboard.admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else {
                  return (
                    <Category
                      key={index}
                      name={getServiceName(service)}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(getServicePath(service));
                      }}
                      selected={selectedService === service}
                    />
                  );
                }
              })}
            />
          }
        />

        <div
          className="flex-col"
          style={{
            justifyContent: "space-between",
            width: "80%",
            height: "98%",
          }}
        >
          <div
            className="flex-col"
            style={{ justifyContent: "center", margin: "20% 0 0 0 " }}
          >
            <div
              className="flex-row"
              style={{ justifyContent: "space-around" }}
            >
              <ListService
                serviceName="Magic Forms"
                margin={"5%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("magicForms")}
              />
              <ListService
                serviceName="Core ID"
                margin={"0%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("coreId")}
              />
              <ListService
                serviceName="Firma Electrónica"
                margin={"5%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("firmaNew")}
              />
            </div>
            <div
              className="flex-row"
              style={{ justifyContent: "space-around" }}
            >
              <ListService
                serviceName="HyperFlow"
                margin={"0%"}
                size={"text-xxxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("hyperFlow")}
              />
            </div>
            <div
              className="flex-row"
              style={{ justifyContent: "space-around", marginBottom: "10vh" }}
            >
              <ListService
                serviceName="Dashboard"
                margin={"0%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("dashboard")}
              />
              <ListService
                serviceName="Authentikator"
                margin={"5%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("authentikator")}
              />

              <ListService
                serviceName="Biometrix Pay"
                margin={"0%"}
                size={"text-xxx-large"} //text-xxx-large
                color={"primary-color-40"} //primary-color-40
                activo={activeServices.includes("Biometrix Pay")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
