import { useEffect, useState } from "react";

// const getConstantsView
type ListEditorComponentProps = {
  list?: string[];
  onChange: (list: string[]) => void;
};
const ListEditorComponent: React.FC<ListEditorComponentProps> = ({
  list,
  onChange,
}) => {
  const [localList, setLocalList] = useState<string[]>([]);
  const [localEdited, setLocalEdited] = useState(false);
  useEffect(() => {
    if (list) setLocalList(list);
  }, [list]);

  function changeValue(index: number, value: string) {
    setLocalEdited(true);
    let newList = [...localList];
    newList[index] = value;
    setLocalList(newList);
  }

  function deleteConstant(index: number) {
    setLocalEdited(true);
    let newList = [...localList];
    newList.splice(index, 1);
    setLocalList(newList);
  }

  function addItem() {
    let newList = [...localList];
    newList.push("");
    setLocalList(newList);
    setLocalEdited(true);
  }

  function saveItems() {
    setLocalEdited(false);
    onChange(localList);
  }

  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0,0,0,0.2)",
        borderRadius: "10px",
        overflow: "auto",
      }}
    >
      <table>
        <thead
          className='text-normal text-small primary-color'
          style={{
            height: "30px",
            textAlign: "center",
          }}
        >
          <tr style={{ height: "15px" }}>
            <th style={{ width: "600px" }}>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody
          className={`text-normal text-small primary-color-50`}
          style={{
            textAlign: "center",
            margin: "5px 0 5px 0",
          }}
        >
          {localList.map((listItem, index) => {
            return (
              <tr
                key={index}
                className=''
                style={{
                  padding: "2px",
                  //   flexDirection: "row",
                  //   display: "flex",
                  //   alignItems: "center",
                }}
              >
                <td>
                  <textarea
                    style={{ width: "600px" }}
                    value={listItem}
                    onChange={(e) => {
                      changeValue(index, e.target.value);
                    }}
                    className='text-normal text-small primary-color-80 input1'
                  />
                </td>
                <td>
                  <button
                    className='boton1'
                    onClick={() => {
                      deleteConstant(index);
                    }}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        className='boton1'
        style={{ marginTop: "20px" }}
        onClick={() => {
          addItem();
        }}
      >
        Agregar
      </button>
      {localEdited && (
        <button
          className='boton1'
          style={{ marginTop: "20px" }}
          onClick={() => {onChange(localList);}}
        >
          Guardar Cambios
        </button>
      )}
    </div>
  );
};

export default ListEditorComponent;
