import { useEffect, useState } from "react";

// const getConstantsView
type UploadSupportFileComponentProps = {
  upload(): void;
};
const UploadSupportFileComponent: React.FC<UploadSupportFileComponentProps> = ({
  upload,
}) => {
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0,0,0,0.2)",
        borderRadius: "10px",
        overflow: "auto",
      }}
    >
      <input
        id='UploadFileModalButton'
        style={{ display: "none" }}
        type='file'
        onChange={(e) => {
          let file = e.target.files?.[0];
          if (file) {
            setFileName(file.name);
            setFile(file);
          }
        }}
        value={fileName}
      ></input>
       <button
        className='boton1'
        style={{ marginTop: "20px" }}
        onClick={() => {
          document.getElementById("UploadFileModalButton")?.click();
        }}
      >
        Seleccionar archivo
      </button>
      <h1 className="text-medium" style={{marginLeft: "10px", marginTop: "20px"}}>Archivo seleccionado: {fileName}</h1>
     
      <button
        className='boton1'
        style={{ marginTop: "20px" }}
        onClick={() => {}}
      >
        Subir archivo
      </button>
    </div>
  );
};

export default UploadSupportFileComponent;
