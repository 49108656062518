import axiosApiInstance from "../../../helpers/interceptor";
import { RegistrationDetail } from "../coreid/coreIdAPI";
import { GetSignatureResponse } from "../firmanew/firmaNewAPI";
import { FilledForm } from "../magicforms/magicFormsAPI";
import { UnifiedAlertsFiltered } from "../alerts/alertsAPI";

let getFiltered = "smartFlow/getFiltered";
let getFilterOptions = "smartFlow/getFilterOptions";

let getFlowProcess = (flowId: string) => `smartFlow/getFlowProcess/${flowId}`;
let changeStatus = (flowId: string) => `smartFlow/changeStatus/${flowId}`;

const getFilteredFlowAPI = async (
  params: GetFilteredFlowRequest
): Promise<{ flows: FlowFiltered | null; error: string }> => {
  try {
    let registrationsResponse = await axiosApiInstance.post(
      `/${getFiltered}`,
      params
    );
    return {
      flows: registrationsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flows: null,
    };
  }
};

const getFilterOptionsFlowAPI = async (): Promise<{
  filterOptions: FlowFilteredOptions | null;
  error: string;
}> => {
  try {
    let filterOptionsResponse = await axiosApiInstance.get(
      `/${getFilterOptions}`
    );
    return {
      filterOptions: filterOptionsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      filterOptions: null,
    };
  }
};

const getFlowByIdAPI = async (
  flowId: string
): Promise<{ flow: Flow | null; error: string }> => {
  try {
    let flowResponse = await axiosApiInstance.get(`/${getFlowProcess(flowId)}`);
    return {
      flow: flowResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flow: null,
    };
  }
};

const changeFlowStatusAPI = async (
  newStatus: string,
  comment: string,
  flowId: string
): Promise<{ flow: WebhookData | null; error: string }> => {
  try {
    let updatedFlow = await axiosApiInstance.post(`/${changeStatus(flowId)}`, {
      comment,
      newStatus,
    });
    return {
      flow: updatedFlow.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flow: null,
    };
  }
};

export interface GetFilteredFlowRequest {
  searchQuery: string | null;
  page: number;
  pageSize?: number;
  startDate: string | null;
  endDate: string | null;
  flowId: string | null;
  status: string | null;
}

export interface FlowFiltered {
  flows: Flow[];
  numPages: number;
  currentPage: number;
}

export interface Flow {
  id: string;
  userId: string;
  asesorId: string;
  filledFormId: string;
  filledFormIdSeguro: string;
  electronicSignatureId: string;
  electronicSignatureSeguroId: string;
  clientId: string;
  flowId: string;
  status: string;
  step: string;
  redirectionUrl: string;
  errorRedirectionUrl: string;
  codeUsed: string;
  metadata: object;
  registration: RegistrationDetail;
  electronigSig: GetSignatureResponse;
  filledForm: FilledForm;
  filledFormSeguro: FilledForm;
  startDateTime: string;
  endDateTime: string;
  signaturedata: GetSignatureResponse | null;
  comment: string;
}

export interface ExecutedStep {
  id: string;
  status:
    | "DONE"
    | "FAILED_AUTO_RETRY"
    | "FAILED_MANUAL_RETRY"
    | "IN_PROGRESS"
    | "PENDING"
    | "PENDING_MANUAL_REVISION";
  errorData: string;
  customName: string;
  entityId: string;
  stepDescriptionId: string;
  resultData: string[];
  nextStepId: string;
  detailCore?: RegistrationDetail | null;
  detailMagic?: FilledForm | null;
  detailSig?: GetSignatureResponse | null;
}

export interface smartFlow {
  clientId: string;
  smartFlowId: string;
  id: string;
  executedSteps?: { [key: string]: ExecutedStep };
  stateId: string;
  userId: string;
  flowStatus: "AWAITING_MANUAL_INTERACTION" | "DONE" | "IN_PROGRESS";
  metadata: { [key: string]: string };
  currentAssignees: string[];
  currentStepId: string;
  registrationAlerts?: UnifiedAlertsFiltered | null;
  registration?: RegistrationDetail | null;
}

export interface WebhookData {
  executedUrls: Array<string>;
  errorUrls: Array<string>;
  errorDescriptions: Array<WebhookErrorDescription>;
}

export interface WebhookErrorDescription {
  url: string;
  msg: string;
}

export type FlowFilteredOptions = object;

export {
  getFilteredFlowAPI,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  changeFlowStatusAPI,
};
