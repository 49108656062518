import fileDownload from "js-file-download";
import axiosApiInstance from "../../../helpers/interceptor";
import { File } from "../firma/firmaAPI";

let url = "https://dev.api.firma.anteia.co";
//let url = "https://api.sign.anteia.co";
//let url = "http://localhost:5000";
let getFilteredPath = "api/Backoffice/getFilteredPagares";
let getByIdPath = "api/Backoffice/getPagareById";
let getDocumentsPath = "api/Backoffice/getPagareDocuments";
let getFieldsPagare = "api/Backoffice/getPagareFields";
let downloadData = "api/Backoffice/downloadFile";
let filloutPath = "api/Backoffice/fillout";
let endosoPath = "api/Backoffice/beginEndoso";
let sendOTPPath = "pagares/sendOtpConfirm";
let confirmOtpPath = "pagares/confirmOtp";
let pagareOptionsPath = "api/Backoffice/getPagareOptions";
let createPagarePath = "api/Backoffice/getUrlBasic";

export const getFilteredPagaresAPI = async (
  request: GetFilteredPagareRequest
): Promise<{
  error: string;
  filteredPagares: GetFilteredPagareResponse | null;
}> => {
  try {
    let filteredPagares = await axiosApiInstance.post(
      `${url}/${getFilteredPath}`,
      request
    );
    return {
      error: "",
      filteredPagares: {
        ...filteredPagares.data,
        currentPage: request.page,
      },
    };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { filteredPagares: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { filteredPagares: null, error: e.response.data };
    } else {
      return { filteredPagares: null, error: e.response.data };
    }
  }
};

export const getPagareByIdAPI = async (
  id: string
): Promise<{ pagare: GetPagareResponse | null; error: string }> => {
  try {
    let pagare = await axiosApiInstance.get(`${url}/${getByIdPath}/${id}`);
    return { pagare: pagare.data, error: "" };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { pagare: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { pagare: null, error: e.response.data };
    } else {
      return { pagare: null, error: e.response.data };
    }
  }
};

export const getOwnerByPagare = async (id: string) => {
  try {
    let pagare = await axiosApiInstance.get<PagareOwner>(
      `${url}/api/Backoffice/getOwnerByPagare/${id}`
    );
    return { owner: pagare.data, error: "" };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { owner: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { owner: null, error: e.response.data };
    } else {
      return { owner: null, error: e.response.data };
    }
  }
};

export const getPagareDocumentsAPI = async (
  id: string
): Promise<{ documents: GeneratedDocumentsPagare | null; error: string }> => {
  try {
    let documents = await axiosApiInstance.get(
      `${url}/${getDocumentsPath}/${id}`
    );
    return { documents: documents.data, error: "" };
  } catch (err: any) {
    return { documents: null, error: err && err.message };
  }
};

export const getPagareFieldsAPI = async (
  id: string
): Promise<Array<FieldBackoffice>> => {
  try {
    let fields = await axiosApiInstance.get(`${url}/${getFieldsPagare}/${id}`);
    return fields.data;
  } catch (err: any) {
    return [];
  }
};

export const downloadFileAPI = async (fileName: string) => {
  try {
    let res = await axiosApiInstance.post(`${url}/${downloadData}`, {
      fileName,
    });
    let urlpdf = `data:application/pdf;base64,${res.data}`;
    fetch(urlpdf)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(blob, fileName);
      });
    return;
  } catch {
    return;
  }
};

export const sendOtpConfirmAPI = async (req: SendPagareConfirmationOtp) => {
  try {
    let response = await axiosApiInstance.post<boolean>(`/${sendOTPPath}`, req);
    return response.data;
  } catch (err: any) {
    return false;
  }
};

export const confirmOtpAPI = async (
  req: ConfirmOtpRequest
): Promise<ConfirmOtpResponse> => {
  try {
    let response = await axiosApiInstance.post<ConfirmOtpResponse>(
      `/${confirmOtpPath}`,
      req
    );

    return response.data;
  } catch (err: any) {
    return {
      idConfirmedPagare: "",
    };
  }
};

export const filloutAPI = async (req: FillOutRequest) => {
  try {
    let response = await axiosApiInstance.post<boolean>(
      `${url}/${filloutPath}`,
      req
    );
    return response.data;
  } catch (err: any) {
    return false;
  }
};

export const endosarAPI = async (req: EndosarRequest) => {
  try {
    let response = await axiosApiInstance.post<boolean>(
      `${url}/${endosoPath}`,
      req
    );
    return response.data;
  } catch (err: any) {
    return false;
  }
};

export const getCreatePagareOptionsAPI = async () => {
  try {
    let response = await axiosApiInstance.get<CreatePagareOptions>(
      `${url}/${pagareOptionsPath}`
    );
    return response.data;
  } catch {
    return null;
  }
};

export const getUrlPagareAPI = async (req: GeneratePagareRequest) => {
  let response = await axiosApiInstance.post<string>(
    `${url}/${createPagarePath}`,
    req
  );
  return response.data;
};

export interface GetFilteredPagareRequest {
  type: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  page: number;
  pageSize?: number;
}

export interface GetFilteredPagareResponse {
  numPages: number;
  currentPage: number;
  filteredPagares: Array<FilteredPagare>;
}

export interface FilteredPagare {
  pagareRootId: string;
  noPagare: string;
  deudoresName: Array<string>;
  pagareType: string;
  value: string;
  currency: string;
  versionDate: string;
  status: string;
}

export interface GetPagareResponse {
  deudores: Array<DeudorPagare>;
  ledgerAnnotations: Array<LedgerPagare>;
  type: string;
  status: string;
  value: string;
  currency: string;
  id: string;
  currentClientId: string;
  statusData: StatusDataPagare;
  documents: GeneratedDocumentsPagare | null;
}

export interface StatusDataPagare {
  hasBeenEndosado: boolean;
  hasBeenFilled: boolean;
  hasBeenSigned: boolean;
}

export interface DeudorPagare {
  nombre: string;
  apellido: string;
  identification: string;
  phoneNumber: string;
  email: string;
  userId: string;
}

export interface LedgerPagare {
  action: string;
  fecha: string;
  descripcion: string;
  descripcionDetail: string;
}

export interface GeneratedDocumentsPagare {
  pagare: File;
  carta: File;
  extraDocuments: Array<PagareExtraDocumentsInfo>;
}

export interface PagareExtraDocumentsInfo {
  name: string;
  fileName: string;
}

export interface FieldBackoffice {
  label: string;
  regex: string;
  type: string;
  placeHolder: string;
  value: string;
  id: string;
}

export interface SendPagareConfirmationOtp {
  pagareRootId: string;
  sendAgain: boolean;
}

export interface ConfirmOtpRequest {
  pagareRootId: string;
  code: string;
}

export interface ConfirmOtpResponse {
  idConfirmedPagare: string;
}

export interface FillOutRequest {
  idConfirmation: string;
  fields: Array<FieldBackoffice>;
}

export interface EndosoData {
  identificationType: string;
  identification: string;
  naturalPerson: boolean;
  idTypeLegalRepresentative: string;
  idLegalRepresentative: string;
  email: string;
}

export interface EndosarRequest {
  endosante: EndosoData;
  endosatario: EndosoData;
  idConfirmation: string;
  sellValue: number;
  withResponsability: boolean;
}

export interface PagareOwner {
  identification: string;
  identificationType: string;
  naturalPerson: boolean;
  idTypeLegalRepresentative: string;

  confirmed: boolean;
  ownerId: string;
  email: string;
}

export interface CreatePagareOptions {
  projectOptions: Array<ProjectOption>;
  pagareTemplateOptions: Array<PagareTemplateOption>;
}

export interface ProjectOption extends PagareOption {}

export interface PagareTemplateOption extends PagareOption {}

export interface PagareOption {
  id: string;
  name: string;
}

export interface GeneratePagareRequest {
  projectId: string;
  redirectionUrl: string;
  documentName: string;
  documentBase64: string;
  pagareTemplateId: string;
  value: number;
  currency: string;
}

export interface PagareDownloadReq {
  fileName: string;
  index: number;
}
