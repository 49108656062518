import "bootstrap/dist/css/bootstrap.min.css";
import fileDownload from "js-file-download";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as HyperFlowAlert } from "../../../../Assets/alerts/hyperFlowAlerts.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import { ReactComponent as DownloadIcon2 } from "../../../../Assets/common/images/downloadIcon2.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import {
  changeCurrentActiveComponent,
  changeStatusById,
  clearWebhookData,
  getAttatchmentByRegistrationId,
  getFlowById,
  getFlowFilledFormPDF,
  getFlowFilledFormSeguroPDF,
  getSmartFlowFormById,
  getSmartFlowFormSeguroById,
  getSmartFlowRegistrationImages,
  getSmartFlowSignatureById,
  getSmartFlowSignatureDocumentsById,
  getSmartFlowSignatureDocumentsSeguroById,
  getSmartFlowSignatureSeguroById,
  resetFlow,
  selectCurrentFlow,
  selectDocumentLoadingFlow,
  selectFilledForm,
  selectFilledFormSeguro,
  selectFormPDFBase64,
  selectFormSeguroPDFBase64,
  selectImagesRegistration,
  selectLoadingFlow,
  selectLoadingForm,
  selectLoadingFormSeguro,
  selectLoadingImagesRegistration,
  selectLoadingSeguroPdfLoading,
  selectLoadingSeguroSignature,
  selectLoadingSeguroSignatureDocs,
  selectLoadingSignature,
  selectLoadingSignatureDocs,
  selectSignature,
  selectSignatureDocs,
  selectSignatureDocsSeguro,
  selectSignatureSeguro,
  selectSmartFlowActiveComponent,
  selectSmartFlowLoadingAttachments,
  selectSmartFlowUserAttachments,
  selectWebhookData,
  setIsFlowDetail,
} from "../smartFlowSlice";
import {
  downloadImagesZip,
  emptyCurrentAttatchment,
  getAttachmentsZip,
  getAttatchmentData,
  selectAttachmentToVisualize,
  selectAttatchmentDownloadingStatus,
  selectCurrentAttatchment,
  selectCurrentRegistration,
  selectDownloadZipLoading,
  selectLoadingAttachmentZip,
  setAttachmentToVisualize,
} from "../../coreid/coreIdSlice";
import { getRegistrationById } from "../../coreid/coreIdSlice";
import {
  BiometryImages,
  Generalmages,
} from "./../../hyperFlow/detail/components/CoreIdImages";
import { ExpandibleComponent } from "./../../hyperFlow/detail/ExpandibleComponent";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { LoadingStatus, selectRoles } from "../../../common/commonSlice";
import { FilledForm } from "../../magicforms/magicFormsAPI";
import { StateComponent } from "../../hyperFlow/detail/components/StateComponent";
import { ExpandibleComponentList } from "../../hyperFlow/detail/ExpandibleComponentList";
import { File } from "../../firma/firmaAPI";
import Modal, { Styles } from "react-modal";
import { getAsesores, selectAsesores } from "../../analytics/analyticsSlice";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import {
  Attatchment,
  ListsRenderResult,
  MultiFactorMatrixData,
} from "../../coreid/coreIdAPI";
import { AlertDot } from "../../alerts/components/AlertDot";
import { getStatusName } from "../../firma/helpers/utils";
const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "60%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const Flow = () => {
  let activeComponent = useAppSelector(selectSmartFlowActiveComponent);

  let loadingFlow = useAppSelector(selectLoadingFlow);
  let documentLoading = useAppSelector(selectDocumentLoadingFlow);
  let currentFlow = useAppSelector(selectCurrentFlow);
  let formPDF = useAppSelector(selectFormPDFBase64);

  let form = useAppSelector(selectFilledForm);

  let loadingForm = useAppSelector(selectLoadingForm);
  let signature = useAppSelector(selectSignature);
  let loadingSignature = useAppSelector(selectLoadingSignature);
  let signatureDocs = useAppSelector(selectSignatureDocs);
  let loadingSignatureDocs = useAppSelector(selectLoadingSignatureDocs);
  let pictures = useAppSelector(selectImagesRegistration);
  let loadingPictures = useAppSelector(selectLoadingImagesRegistration);

  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let loadingZip = useAppSelector(selectDownloadZipLoading);
  let roles = useAppSelector(selectRoles);

  let webhookData = useAppSelector(selectWebhookData);
  //Seguro Selectors
  let formSeguro = useAppSelector(selectFilledFormSeguro);
  let formPdfSeguro = useAppSelector(selectFormSeguroPDFBase64);
  let loadingFormSeguro = useAppSelector(selectLoadingFormSeguro);
  let loadingDocumentSeguro = useAppSelector(selectLoadingSeguroPdfLoading);

  //firma seguro
  let signatureSeguro = useAppSelector(selectSignatureSeguro);
  let signatureSeguroDocs = useAppSelector(selectSignatureDocsSeguro);
  let loadingSignatureSeguro = useAppSelector(selectLoadingSeguroSignature);
  let loadingSignatureDocsSeguro = useAppSelector(
    selectLoadingSeguroSignatureDocs
  );
  // asesores
  // Asesores

  //Attachments
  let downloadingAttatchmentZip = useAppSelector(selectLoadingAttachmentZip);
  let downloadingAttatchment = useAppSelector(
    selectAttatchmentDownloadingStatus
  );
  let currentAttatchment = useAppSelector(selectCurrentAttatchment);
  let attachmentToVisualize = useAppSelector(selectAttachmentToVisualize);
  let currentAttachments = useAppSelector(selectSmartFlowUserAttachments);
  let loadingAttatchments = useAppSelector(selectSmartFlowLoadingAttachments);

  const [selectedDownload, setSelectedDownload] = useState(-1);


  // asesores
  // Asesores
  let asesores = useAppSelector(selectAsesores);

  const getAsesorInfo = (asesorId: string) => {
    let asesor = asesores.find((asesor) => asesor.id === asesorId);
    if (asesor) {
      return (
        <a
          href={`https://forms.anteia.co/asesores?idAsesor=${asesor.id}`}
          target='_blank'
        >
          <div
            className='flex-col text-medium primary-color'
            style={{
              cursor: "pointer",
              background: "rgba(0,0,0,0.4)",
              width: "95%",
              borderRadius: "16px",
              padding: "2% 0 10px 3%",
            }}
          >
            <h5
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "white",
                textAlign: "center",
              }}
            >
              Asesor asociado
            </h5>

            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Nombre: <strong>{asesor.name}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Correo: <strong>{asesor.email}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Identificación : <strong>{asesor.identification}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Oficina : <strong>{asesor.asesorData.oficina}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Cargo : <strong>{asesor.asesorData.cargo}</strong>
            </h6>
          </div>
        </a>
      );
    } else {
      return "";
    }
  };

  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const [certificado, setCertificado] = useState(false);
  const [certificadoSeguro, setCertificadoSeguro] = useState(false);
  const [currentSection, setCurrentSection] = useState<string>("");

  const [servicealerts, setservicealerts] = useState<string[]>([]);
  const [currentSectionCoreId, setCurrentSectionCoreId] = useState<
    | "DOCUMENTO DE IDENTIDAD"
    | "BIOMETRÍA"
    | "ESTADO DEL REGISTRO"
    | "MATRIZ DE VERIFICACIÓN"
    | "LISTAS RESTRICTIVAS"
  >("DOCUMENTO DE IDENTIDAD");
  const [currentSectionMagic, setCurrentSectionMagic] = useState<
    "FORMULARIO" | "CAMPOS"
  >("FORMULARIO");
  const [currentSectionFirma, setCurrentSectionFirma] = useState<
    "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES"
  >("DOCUMENTOS");

  const [currentSectionFirmaSeguro, setCurrentSectionFirmaSeguro] = useState<
    "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES"
  >("DOCUMENTOS");

  // ------------------------------Modal Stuff------------------------------------------
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
    dispatch(clearWebhookData());
  };
  //------------------------------GET ROW INFO CLIENT------------------------------------------

  useEffect(() => {
    if (webhookData) {
      if (webhookData.errorDescriptions.length > 0) {
        webhookData.errorDescriptions.forEach((it) => {
          console.log("WebHook Error on:");
          console.log(it.msg);
          console.log(it.url);
          setModalOpen(true);
        });
      }
    }
  }, [webhookData]);

  useEffect(() => {
    if (currentAttatchment.fileBase64 !== "") {
      let url = `data:application/pdf;base64,${currentAttatchment.fileBase64}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, currentAttatchment.fileName);
        });
      dispatch(emptyCurrentAttatchment());
    }
  }, [currentAttatchment]);

  let getRowInfoClient = (name: String, value: String) => {
    return (
      <div className='flex-row'>
        <div className='flex-col' style={{ width: "50%" }}>
          {name}
        </div>
        <div className='flex-col' style={{ width: "50%" }}>
          {value}
        </div>
      </div>
    );
  };

  //-----------------------------GET CURRENT SECTION CONTENT----------------------------------------

  let getCurrentSectionContent = (name: String) => {
    switch (name) {
      case "CORE_ID":
        return getCurrentContentCoreId();
      case "MAGIC_FORMS":
        return getCurrentContentMagic();
      case "MAGIC_FORMS_SEGURO":
        return getCurrentContentMagicSeguro();
      case "ELECTRONIC_SIGNATURE":
        return getCurrentContentFirma();
      case "ELECTRONIC_SIGNATURE_SEGURO":
        return getCurrentContentFirmaSeguro();
    }
  };
  const getMatrixAlert = (active: boolean, value?: MultiFactorMatrixData) => {
    if (!active) {
      return false;
    }
    if (!value) {
      return false;
    }
    if (value.alerts.length > 0) {
      return true;
    }
    if (!value.passes) {
      return true;
    }
    return false;
  };
  const getListRisk = (risk: boolean, warning: boolean) => {
    if (risk) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert='HIGH' />
        </div>
      );
    } else if (warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert='MEDIUM' />
        </div>
      );
    } else if (!risk && !warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5 style={{ fontSize: "14px", fontWeight: 700, color: "white" }}>
            No
          </h5>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            No
          </h5>
          <AlertDot levelAlert='NONE' />
        </div>
      );
    }
  };
  const getMatrixDetailData = (value: MultiFactorMatrixData, name: string) => {
    return (
      <div
        className='flex-col'
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className='text-medium text-bold primary-color-50'
          style={{ width: "90%", textAlign: "center", fontSize: "11px" }}
        >
          {name}
        </div>
        <div className='text-huge text-bold primary-color'>{value.score}%</div>
        <div
          className='text-medium text-bold primary-color-50'
          style={{
            width: "80%",
            textAlign: "center",
            fontSize: "11px",
            wordWrap: "break-word",
          }}
        >
          Umbral de bloqueo: {value.blockingThreshold}%
        </div>
      </div>
    );
  };
  //-------------------------------GET DOCUMENT SQUARE(MAGIC)------------------------------------------
  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm,
    formPdfInner: string | null
  ) => {
    if (filledForm) {
      switch (loadingPDFStatus) {
        case "idle":
          return (
            <div className='primary-color text-xx-large text-bold'>
              Formulario lleno
            </div>
          );
        case "pending":
          return (
            <LoadingOverlay
              active={loadingPDFStatus === "pending"}
              spinner={<ClipLoader color='white' size='150px' />}
            ></LoadingOverlay>
          );
        case "rejected":
          return (
            <div className='primary-color text-xx-large text-bold'>
              Hubo un problema cargando del documento
            </div>
          );
        case "resolved":
          return (
            <object
              data={base64PDFToBlobUrl(formPdfInner!)}
              type='application/pdf'
              width='100%'
              height='100%'
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <p
                  style={{ color: "white", width: "80%", textAlign: "center" }}
                >
                  Tu navegador no permite visualizar PDFs, puedes descargar el
                  documento aquí{" "}
                  <a
                    style={{ color: "white", textDecoration: "underline" }}
                    onClick={() => {
                      const url = `data:application/pdf;base64,${formPdfInner}`;
                      fetch(url)
                        .then((res) => res.blob())
                        .then((blob) => {
                          fileDownload(
                            blob,
                            "Documento.pdf",
                            "application/pdf"
                          );
                        });
                    }}
                  >
                    Descargar
                  </a>
                </p>
              </div>
            </object>
          );
        default:
          return (
            <div className='primary-color text-xx-large text-bold'>
              Formulario lleno
            </div>
          );
      }
    } else {
      return (
        <div className='primary-color text-xx-large text-bold'>
          No se ha llenado el formulario
        </div>
      );
    }
  };

  let { id } = useParams();
  useEffect(() => {
    if ((!currentRegistration || currentRegistration.id !== id) && id !== "") {
      dispatch(getRegistrationById(id || ""));
    }
  }, [currentRegistration, id]);

  useEffect(() => {
    if (activeComponent !== "Detail") {
      dispatch(changeCurrentActiveComponent("Detail"));
    }
    if (loadingFlow === "idle") {
      dispatch(getAsesores());
    }
    if (id !== "" && loadingFlow === "idle") {
      dispatch(getFlowById(id || ""));
    }
  }, [loadingFlow]);

  useEffect(() => {
    window.onpopstate = function (event) {
      dispatch(dispatch(resetFlow()));
    };
    if ((!currentFlow || currentFlow.id !== id) && id !== "") {
      dispatch(getFlowById(id || ""));
    }
    if (currentFlow && currentFlow.filledFormId && documentLoading === "idle") {
      dispatch(getFlowFilledFormPDF(currentFlow.filledFormId));
    }

    if (currentFlow && currentFlow.filledFormId && loadingForm === "idle") {
      dispatch(getSmartFlowFormById(currentFlow.filledFormId));
    }

    if (currentFlow?.electronicSignatureId && loadingSignature === "idle") {
      dispatch(getSmartFlowSignatureById(currentFlow.electronicSignatureId));
    }

    if (currentFlow?.electronicSignatureId && loadingSignatureDocs === "idle") {
      dispatch(
        getSmartFlowSignatureDocumentsById(currentFlow.electronicSignatureId)
      );
    }
    //Seguro Firma
    if (
      currentFlow?.electronicSignatureSeguroId &&
      loadingSignatureSeguro === "idle"
    ) {
      dispatch(
        getSmartFlowSignatureSeguroById(currentFlow.electronicSignatureSeguroId)
      );
    }
    if (
      currentFlow?.electronicSignatureSeguroId &&
      loadingSignatureDocsSeguro === "idle"
    ) {
      dispatch(
        getSmartFlowSignatureDocumentsSeguroById(
          currentFlow.electronicSignatureSeguroId
        )
      );
    }

    if (currentFlow && currentFlow.userId && loadingPictures === "idle") {
      dispatch(getSmartFlowRegistrationImages(currentFlow.userId));
    }
    // //Get Seguro stuff
    if (currentFlow?.filledFormIdSeguro && loadingFormSeguro === "idle") {
      dispatch(getSmartFlowFormSeguroById(currentFlow.filledFormIdSeguro));
    }
    if (currentFlow?.filledFormIdSeguro && loadingDocumentSeguro === "idle") {
      dispatch(getFlowFilledFormSeguroPDF(currentFlow.filledFormIdSeguro));
    }
    //Attachment
    if (currentFlow && loadingAttatchments === "idle") {
      dispatch(getAttatchmentByRegistrationId(currentFlow.userId));
    }

    dispatch(setIsFlowDetail(true));

    return () => {};
  }, [id, currentFlow, documentLoading]);

  //-----------------------------GET DETAIL DATA------------------------------------------
  const getDetailData = (value: string) => {
    return (
      <div
        className='flex-col'
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className='text-medium text-bold primary-color-50'
          style={{ width: "145px", textAlign: "center", fontSize: "11px" }}
        >
          Score
        </div>
        <div className='text-huge text-bold primary-color'>{value}</div>
      </div>
    );
  };

  const getDescriptionFromMatrixData = (value: MultiFactorMatrixData) => {
    if (value.alerts.length > 0) {
      return value.alerts.join("| ");
    } else {
      return "";
    }
  };

  //-----------------------------TEXT_VALUE------------------------------------------
  let getTextValue = (name: String, value: String, alert: boolean) => {
    return (
      <>
        <div className='flex-col'>
          <div className='flex-col text-small primary-color-40'>{name}</div>
          <div
            className={`flex-row text-small ${
              alert ? "text-bold alert-color" : "primary-color"
            }`}
          >
            <div className='col-2'>{alert ? <HyperFlowAlert /> : ""}</div>
            <div className='col-10'>{value}</div>
          </div>
        </div>
      </>
    );
  };
  //-------------------------------GET ALERT------------------------------------------
  let getAlert = (name: String) => {
    switch (name) {
      case "CAMPOS":
        return servicealerts.includes("MagicForms");
      case "DOCUMENTO DE IDENTIDAD":
        return servicealerts.includes("coreId");
    }

    return false;
  };

  //-------------------------------GET MENU ITEM CORE_ID------------------------------------------
  let getMenuItemCoreId = (
    name:
      | "DOCUMENTO DE IDENTIDAD"
      | "BIOMETRÍA"
      | "ESTADO DEL REGISTRO"
      | "MATRIZ DE VERIFICACIÓN"
      | "LISTAS RESTRICTIVAS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className='flex-row'
        onClick={() => {
          setCurrentSectionCoreId(name);
        }}
        style={{
          background:
            currentSectionCoreId === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className='flex-col' style={{ width: "20%" }}>
          {getAlert(currentSectionCoreId) ? <HyperFlowAlert /> : ""}
        </div>
        <div className='flex-col' style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET MENU ITEM MAGIC------------------------------------------
  let getMenuItemMagic = (
    name: "FORMULARIO" | "CAMPOS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className='flex-row'
        onClick={() => {
          setCurrentSectionMagic(name);
        }}
        style={{
          background:
            currentSectionMagic === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className='flex-col' style={{ width: "20%" }}>
          {getAlert(currentSectionMagic) ? <HyperFlowAlert /> : ""}
        </div>
        <div className='flex-col' style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET MENU ITEM FIRMAS------------------------------------------
  let getMenuItemFirmas = (
    name: "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className='flex-row'
        onClick={() => {
          setCurrentSectionFirma(name);
        }}
        style={{
          background:
            currentSectionFirma === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className='flex-col' style={{ width: "20%" }}>
          {getAlert(currentSectionFirma) ? <HyperFlowAlert /> : ""}
        </div>
        <div className='flex-col' style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET MENU ITEM FIRMAS------------------------------------------
  let getMenuItemFirmasSeguro = (
    name: "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className='flex-row'
        onClick={() => {
          setCurrentSectionFirmaSeguro(name);
        }}
        style={{
          background:
            currentSectionFirmaSeguro === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className='flex-col' style={{ width: "20%" }}>
          {getAlert(currentSectionFirmaSeguro) ? <HyperFlowAlert /> : ""}
        </div>
        <div className='flex-col' style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET CURRENT SECTION MENU------------------------------------------
  let getCurrentSectionMenu = (name: String) => {
    switch (name) {
      case "CORE_ID":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "100%" }}
          >
            {getMenuItemCoreId("DOCUMENTO DE IDENTIDAD", true, false)}
            {getMenuItemCoreId("BIOMETRÍA", false, false)}
            {getMenuItemCoreId("ESTADO DEL REGISTRO", false, false)}
            {getMenuItemCoreId("MATRIZ DE VERIFICACIÓN", false, false)}
            {getMenuItemCoreId("LISTAS RESTRICTIVAS", false, true)}
          </div>
        );
      case "MAGIC_FORMS":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "80%" }}
          >
            {getMenuItemMagic("FORMULARIO", true, false)}
            {getMenuItemMagic("CAMPOS", false, true)}
          </div>
        );
      case "MAGIC_FORMS_SEGURO":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "80%" }}
          >
            {getMenuItemMagic("FORMULARIO", true, false)}
            {getMenuItemMagic("CAMPOS", false, true)}
          </div>
        );
      case "ELECTRONIC_SIGNATURE":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "80%" }}
          >
            {getMenuItemFirmas("DOCUMENTOS", true, false)}
            {getMenuItemFirmas("FIRMANTES", false, false)}
            {getMenuItemFirmas("HISTORIAL DE ACCIONES", false, true)}
          </div>
        );
      case "ELECTRONIC_SIGNATURE_SEGURO":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "80%" }}
          >
            {getMenuItemFirmasSeguro("DOCUMENTOS", true, false)}
            {getMenuItemFirmasSeguro("FIRMANTES", false, false)}
            {getMenuItemFirmasSeguro("HISTORIAL DE ACCIONES", false, true)}
          </div>
        );
      case "Adjuntos":
        return (
          <div
            className='flex-col text-bold text-medium primary-color'
            style={{ width: "80%" }}
          ></div>
        );
    }
  };

  let getDocumentSign = (loadingPDFStatus: LoadingStatus, sign: File) => {
    switch (loadingPDFStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color='white' size='150px' />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className='primary-color text-xx-large text-bold'>
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={base64PDFToBlobUrl(sign.documentBase64!)}
            type='application/pdf'
            width='100%'
            height='100%'
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <p style={{ color: "white", width: "80%", textAlign: "center" }}>
                Tu navegador no permite visualizar PDFs, puedes descargar el
                documento aquí{" "}
                <a
                  style={{ color: "white", textDecoration: "underline" }}
                  onClick={() => {
                    const url = `data:application/pdf;base64,${sign.documentBase64}`;
                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(blob, "Documento.pdf", "application/pdf");
                      });
                  }}
                >
                  Descargar
                </a>
              </p>
            </div>
          </object>
        );
      default:
        return (
          <div className='primary-color text-xx-large text-bold'>Firma</div>
        );
    }
  };
  //-------------------------------GET CURRENT CONTENT CORE_ID------------------------------------------
  let getCurrentContentCoreId = () => {
    switch (currentSectionCoreId) {
      case "DOCUMENTO DE IDENTIDAD":
        return (
          currentFlow?.registration && (
            <div className='flex-row content-table'>
              <div
                className='col-4 data-colum'
                style={{ padding: "20px", paddingBottom: "10px" }}
              >
                {getTextValue(
                  "Id del registro",
                  currentFlow?.registration.id || "No encontrado",
                  false
                )}
                {getTextValue(
                  "Fecha de nacimiento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.birthDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Número de documento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.documentNumber
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Fecha de expedición",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.expeditionDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Nombre y apellido",
                  currentFlow?.registration
                    ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                    : "No encontrado",
                  currentFlow.registration.multiFactorMatrix
                    ? currentFlow.registration.multiFactorMatrix?.veracity
                        .alerts.length > 0
                    : false
                )}
                {getTextValue(
                  "Lugar de nacimiento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.birthPlace
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Sexo",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.gender
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Tipo de sange (RH)",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.bloodType
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Lugar de expedición",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.expeditionCity
                    : "No encontrado",
                  false
                )}
              </div>
              <div className='col-4 data-colum'>
                <div
                  className='text-bold text-medium primary-color'
                  style={{ textAlign: "center" }}
                >
                  Documento
                  <br />
                </div>
                {currentFlow?.registration && (
                  <Generalmages
                    images={pictures!}
                    loadingImages={loadingPictures}
                  />
                )}

                <div
                  className='text-medium text-normal primary-color'
                  style={{ padding: "10px", textAlign: "center" }}
                >
                  {`OCR: ${
                    currentFlow?.registration.unifiedChecks.ocrCheck.active
                      ? currentFlow?.registration.unifiedChecks.ocrCheck.result
                        ? "OK"
                        : "NO OK"
                      : "Desactivado"
                  }`}
                </div>
                {currentFlow && currentFlow.registration && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className='text-medium text-bold primary-color flex-row'
                      style={{
                        margin: "0% 0 5% 0",
                        background: "rgba(0,0,0,0.4)",
                        paddingRight: "20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "20px",
                        borderRadius: "8px",
                        textAlign: "center",
                        cursor: "pointer",
                        width: "200px",
                        border: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          downloadImagesZip(currentFlow?.registration.id!!)
                        );
                      }}
                    >
                      {loadingZip === "pending" ? (
                        <ClipLoader color='white' size='25px' />
                      ) : (
                        <>
                          Descargar Imagenes{" "}
                          <DownloadIcon2 style={{ marginLeft: "5px" }} />
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className='col-4 data-colum' style={{ padding: "10px" }}>
                {currentFlow?.registration.unifiedChecks.documentValidityCheck
                  .active ? (
                  <ExpandibleComponent
                    alert={getMatrixAlert(
                      currentFlow.registration.unifiedChecks
                        .documentValidityCheck.active,
                      currentFlow.registration.multiFactorMatrix?.validity
                    )}
                    description={
                      currentFlow.registration.unifiedChecks
                        .documentValidityCheck.description
                        ? currentFlow?.registration.unifiedChecks.documentValidityCheck.description.split(
                            "Screen"
                          )[0]
                        : ""
                    }
                    isActive={false}
                    detail={""}
                    title='Autenticidad de documento'
                    content={
                      <div className='flex-row'>
                        <div
                          className='flex-col primary-color-35 pdd-left'
                          style={{ fontSize: "11px", fontWeight: "bold" }}
                        >
                          {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.validity.blockingThreshold}%`}
                        </div>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks
                            .documentValidityCheck.active
                            ? currentFlow?.registration.multiFactorMatrix
                              ? `${(currentFlow?.registration.multiFactorMatrix.validity.score).toFixed(
                                  1
                                )}%`
                              : ""
                            : "Desactivado"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
                {currentFlow.registration.multiFactorMatrix &&
                  currentFlow.registration.multiFactorMatrix.screen.active && (
                    <ExpandibleComponent
                      alert={
                        getMatrixAlert(
                          currentFlow.registration.multiFactorMatrix.screen
                            .active,
                          currentFlow.registration.multiFactorMatrix.screen
                        ) ||
                        getMatrixAlert(
                          currentFlow.registration.multiFactorMatrix.impresion
                            .active,
                          currentFlow.registration.multiFactorMatrix.impresion
                        )
                      }
                      description={`${getDescriptionFromMatrixData(
                        currentFlow.registration.multiFactorMatrix.screen
                      )} | ${getDescriptionFromMatrixData(
                        currentFlow.registration.multiFactorMatrix.impresion
                      )}`}
                      isActive={false}
                      detail={""}
                      title='Captura de documento'
                      content={
                        <div
                          className='flex-row'
                          style={{
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          {getMatrixDetailData(
                            currentFlow?.registration.multiFactorMatrix.screen,
                            "Detección pantalla"
                          )}

                          {getMatrixDetailData(
                            currentFlow?.registration.multiFactorMatrix
                              .impresion,
                            "Detección impresión"
                          )}
                        </div>
                      }
                    />
                  )}

                {currentFlow.registration.multiFactorMatrix &&
                currentFlow.registration.multiFactorMatrix?.veracity ? (
                  <ExpandibleComponentList
                    alert={
                      getMatrixAlert(
                        currentFlow.registration.unifiedChecks
                          .governmentDataMatchCheck.active,
                        currentFlow.registration.multiFactorMatrix?.govEntity
                      ) ||
                      getMatrixAlert(
                        currentFlow.registration.unifiedChecks
                          .governmentDataMatchCheck.active,
                        currentFlow.registration.multiFactorMatrix?.veracity
                      )
                    }
                    alerts={
                      currentFlow.registration.multiFactorMatrix
                        ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.concat(
                            currentFlow.registration.multiFactorMatrix
                              ?.govEntity.alerts
                          )
                        : []
                    }
                    isActive={false}
                    title='Match Gubernamental'
                    content={
                      <div
                        className='flex-row'
                        style={{
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {getMatrixDetailData(
                          currentFlow?.registration.multiFactorMatrix.veracity,
                          "Coincidencia de datos"
                        )}

                        {getMatrixDetailData(
                          currentFlow?.registration.multiFactorMatrix.govEntity,
                          "Alertas gubernamentales"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        );
      case "BIOMETRÍA":
        return currentFlow?.registration ? (
          <div
            className='flex-row content-table'
            style={{ width: "90%", margin: "10px" }}
          >
            <div className='col-6'>
              <BiometryImages
                images={pictures!}
                loadingImages={loadingPictures}
              />
              <div />
            </div>
            <div className='col-6' style={{ padding: "2%", marginLeft: "10%" }}>
              <ExpandibleComponent
                alert={getMatrixAlert(
                  currentFlow.registration.unifiedChecks.facematchCheck.active,
                  currentFlow.registration.multiFactorMatrix?.faceMatch
                )}
                description={
                  currentFlow.registration.unifiedChecks.facematchCheck
                    .description
                    ? currentFlow?.registration.unifiedChecks.facematchCheck
                        .description
                    : ""
                }
                isActive={false}
                detail={""}
                title='Facematch'
                content={
                  <div
                    className='flex-row'
                    style={{ padding: "0 0 0 5px", alignItems: "center" }}
                  >
                    <div
                      className='flex-col primary-color-50 pdd-left'
                      style={{
                        textAlign: "center",
                        justifySelf: "center",
                        padding: "1%",
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.faceMatch.blockingThreshold}%`}
                    </div>
                    {getDetailData(
                      currentFlow?.registration.unifiedChecks.facematchCheck
                        .active
                        ? `${currentFlow?.registration.faceMatchPercent.toFixed(
                            1
                          )}%`
                        : "Desactivado"
                    )}
                  </div>
                }
              />

              <ExpandibleComponent
                alert={getMatrixAlert(
                  currentFlow.registration.multiFactorMatrix?.liveness.active ||
                    false,
                  currentFlow.registration.multiFactorMatrix?.liveness
                )}
                description={
                  currentFlow.registration.multiFactorMatrix?.liveness.active &&
                  currentFlow.registration.multiFactorMatrix?.liveness.alerts
                    .length > 0
                    ? currentFlow.registration.multiFactorMatrix?.liveness.alerts.join(
                        "| "
                      )
                    : ""
                }
                isActive={false}
                detail={""}
                title='Liveness'
                content={
                  <div
                    className='flex-row'
                    style={{ padding: "0 0 0 5px", alignItems: "center" }}
                  >
                    <div
                      className='flex-col primary-color-50 pdd-left'
                      style={{
                        textAlign: "center",
                        justifySelf: "center",
                        padding: "1%",
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.liveness.blockingThreshold}%`}
                    </div>
                    {getDetailData(
                      currentFlow.registration.multiFactorMatrix?.liveness
                        .active
                        ? `${currentFlow?.registration.multiFactorMatrix.liveness.score.toFixed(
                            1
                          )}%`
                        : "Desactivado"
                    )}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case "ESTADO DEL REGISTRO":
        return currentFlow?.registration ? (
          <div className='flex-row content-table' style={{ width: "100%" }}>
            <div className='col-4' style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.listsCheck.description
                }
                isActive={false}
                detail={""}
                title='Listas'
                content={
                  <div
                    className='flex-row primary-color-40'
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.listsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className='col-4' style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.gpsCheck.description
                }
                isActive={false}
                detail={""}
                title='GPS'
                content={
                  <div
                    className='flex-row primary-color-40'
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.gpsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className='col-4' style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.deviceIdCheck
                    .description
                }
                isActive={false}
                detail={""}
                title='Device ID'
                content={
                  <div
                    className='flex-row primary-color-40'
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.deviceIdCheck
                      .result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case "MATRIZ DE VERIFICACIÓN":
        return (
          currentFlow?.registration && (
            <div
              className='flex-row content-table text-normal text-small'
              style={{ width: "100%" }}
            >
              <div className='flex-col' style={{ width: "30%" }}>
                <div
                  className='flex-col primary-color'
                  style={{
                    width: "70%",
                    borderRadius: "8px",
                    background: "rgba(0,0,0,0.3)",
                    padding: "3%",
                    marginLeft: "10px",
                    marginTop: "10%",
                  }}
                >
                  {currentFlow.registration.multiFactorMatrix?.minScore && (
                    <div className='flex-row' style={{ paddingLeft: "20px" }}>
                      {`Umbral: ${currentFlow.registration.multiFactorMatrix?.minScore}%`}
                    </div>
                  )}
                  {currentFlow.registration.multiFactorMatrix?.finalScore && (
                    <div className='flex-row' style={{ paddingLeft: "20px" }}>
                      {`Score Total: ${currentFlow.registration.multiFactorMatrix?.finalScore.toFixed(
                        2
                      )}%`}
                    </div>
                  )}
                </div>

                <table
                  style={{
                    width: "90%",
                    borderCollapse: "collapse",
                    margin: "10px",
                    overflow: "hidden",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <thead
                    className='text-normal text-small primary-color'
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OCR"}</td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.ocrCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OTP"}</td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.otpCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Correo"}</td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.emailCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>
                        {"Dispositivos registrados"}
                      </td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.deviceIdCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Localización"}</td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.gpsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Listas"}</td>
                      <td className='p-1'>
                        {currentFlow.registration.unifiedChecks.listsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className='flex-col'
                style={{ width: "70%", height: "100%" }}
              >
                <table
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    borderCollapse: "collapse",
                    overflow: "hidden",

                    borderRadius: "12px",
                  }}
                >
                  <thead
                    className='text-normal text-small primary-color'
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th className='p-1'>Factor</th>
                      <th className='p-1'>Score (0-100)</th>
                      <th className='p-1'>Umbral de Bloqueo</th>
                      <th className='p-1'>Admisión</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color-30`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                      margin: "5px 0 5px 0",
                    }}
                  >
                    <tr></tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Face match"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.faceMatch
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Detección de impresiones en documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.impresion.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.impresion.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.impresion
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Detección de pantalla para documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.screen
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.screen
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.screen
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Validez-Autenticidad"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.validity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Veracidad documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.veracity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Listas"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.lists
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Liveness"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.liveness
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.liveness
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.liveness
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>{" "}
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"GPS (Geofencing)"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.geolocation.passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Device ID"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.deviceId
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Match datos iniciales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.initialDataMatch.passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className='p-1'>
                      <td></td>
                      <td>{"Alertas entidades gubernamentales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.govEntity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        );
      case "LISTAS RESTRICTIVAS":
        let listas = currentFlow?.registration?.externalQueryResults;
        let renderListas: ListsRenderResult = {
          lists: [],
          numConsultedLists: 0,
          numResultsWithRisk: 0,
          numResultsWithWarning: 0,
        };
        if (listas) {
          for (let listaItem of listas) {
            for (let list of listaItem.listResults) {
              if (list.risk) {
                renderListas.numResultsWithRisk++;
              }
              if (list.warning) {
                renderListas.numResultsWithWarning++;
              }
              if (list.risk || list.warning) {
                renderListas.lists.push(list);
              }
            }
          }
          for (let listaItem of listas) {
            for (let list of listaItem.listResults) {
              renderListas.numConsultedLists++;

              if (!list.risk && !list.warning) {
                renderListas.lists.push(list);
              }
            }
          }
        }
        if (
          currentFlow?.registration?.multiFactorMatrix?.lists?.active ===
            false ||
          !listas
        ) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ color: "white", fontSize: "18px", fontWeight: 700 }}>
                No se encontraron resultados
              </h5>
            </div>
          );
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              height: "100%",
              justifyContent: "start",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginBottom: "14px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{ color: "white", fontSize: "13px", fontWeight: 700 }}
                >
                  Listas consultadas
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: 700,
                      margin: "0 5px 0 0 ",
                    }}
                  >
                    {renderListas.numConsultedLists}
                  </h5>
                  {renderListas.numConsultedLists > 0 && (
                    <AlertDot levelAlert='NONE' />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{ color: "white", fontSize: "13px", fontWeight: 700 }}
                >
                  Listas con riesgo
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: 700,
                      margin: "0 5px 0 0 ",
                    }}
                  >
                    {renderListas.numResultsWithRisk}
                  </h5>
                  {renderListas.numResultsWithRisk > 0 && (
                    <AlertDot levelAlert='HIGH' />
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "auto",
                height: "80%",
                marginBottom: "14px",
              }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className='text-normal text-medium primary-color-35'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre lista</th>
                    <th>Tiene riesgo</th>
                    <th>Coincidencia por documento</th>
                    <th>Coincidencia por nombre</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className='text-normal text-x-small primary-color'
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td></td>
                    <td style={{ width: "40%" }}></td>
                    <td></td> <td></td>
                    <td></td> <td></td>
                    <td></td>
                  </tr>
                  {renderListas.lists.map((list, i) => {
                    return (
                      <tr
                        key={i}
                        className='list-hover'
                        style={{ height: "30px" }}
                      >
                        <td></td>
                        <td>{list.listName}</td>
                        <td>{getListRisk(list.risk, false)}</td>
                        <td>{list.documentMatch ? "Sí" : "No"}</td>
                        <td>{list.nameMatch ? "Sí" : "No"}</td>

                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  //-------------------------------GET CURRENT CONTENT MAGIC FORMS------------------------------------------
  let getCurrentContentMagic = () => {
    switch (currentSectionMagic) {
      case "FORMULARIO":
        return (
          form && (
            <div
              style={{ display: "flex", flexDirection: "row", height: "100%" }}
            >
              <div
                className='flex-col'
                style={{ width: "30%", padding: "0 5px 10px 20px" }}
              >
                <div className='text-normal text-small primary-color-35'>
                  {` Nombre: ${form ? form.pdfName : ""}`}
                </div>
                <div className='text-normal text-small primary-color-35'>
                  {` ID Documento: ${form ? form.id : ""}`}
                </div>
                <div style={{ marginTop: "auto", padding: "1%" }}>
                  <p
                    style={{
                      color: "rgba(255,255,255,0.5)",
                      fontSize: "11px",
                      marginBottom: "0",
                    }}
                  >
                    Si no puedes visualizarlo el documento, puedes descargarlo:
                  </p>
                  <button
                    className='flex-row text-small primary-color'
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (form) {
                        let url = `data:application/pdf;base64,${formPDF}`;

                        fetch(url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              form?.pdfName ? form.pdfName : "Document.pdf"
                            );
                          });
                      }
                    }}
                  >
                    {"Descargar "}
                    <div style={{ padding: "2%" }}>
                      <DownloadIcon style={{ margin: "4px" }} />
                    </div>
                  </button>
                </div>
              </div>

              <div className='flex-col' style={{ width: "70%" }}>
                {getDocument(loadingFlow, form!, formPDF)}
              </div>
            </div>
          )
        );
      case "CAMPOS":
        return (
          form && (
            <div
              className='flex-col primary-color-35'
              style={{ width: "100%", overflow: "auto", height: "250px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  background: "rgba(0,0,0,0.2)",
                  margin: "0 1% 1% 1%",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    width: "100%",
                    borderBottom: "1px rgba(255,255,255,0.4)",
                    background: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Información Solicitada
                  </div>
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Respuesta
                  </div>
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Tipo de Campo
                  </div>
                </div>
                {form.requirements.map((e) => {
                  let response = form?.data[e.stitchTypeId];
                  return (
                    <div
                      key={e.stitchTypeId}
                      style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        width: "100%",
                        borderBottom: "1px rgba(255,255,255,0.4)",
                      }}
                    >
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {e.stitchTypeId}
                      </div>
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {response}
                      </div>
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        );
      default:
        return <></>;
    }
  };
  //--------------------------------GET CURRENT CONTENT MAGIC FORMS SEGURO-----------------------
  let getCurrentContentMagicSeguro = () => {
    switch (currentSectionMagic) {
      case "FORMULARIO":
        return (
          formSeguro && (
            <div
              style={{ display: "flex", flexDirection: "row", height: "100%" }}
            >
              <div
                className='flex-col'
                style={{ width: "30%", padding: "0 5px 10px 20px" }}
              >
                <div className='text-normal text-small primary-color-35'>
                  {` Nombre: ${formSeguro ? formSeguro.pdfName : ""}`}
                </div>
                <div className='text-normal text-small primary-color-35'>
                  {` ID Documento: ${formSeguro ? formSeguro.id : ""}`}
                </div>

                <div style={{ marginTop: "auto", padding: "1%" }}>
                  <p
                    style={{
                      color: "rgba(255,255,255,0.5)",
                      fontSize: "11px",
                      marginBottom: "0",
                    }}
                  >
                    Si no puedes visualizarlo el documento, puedes descargarlo:
                  </p>
                  <button
                    className='flex-row text-small primary-color'
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (form) {
                        let url = `data:application/pdf;base64,${formPdfSeguro}`;

                        fetch(url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              formSeguro?.pdfName
                                ? formSeguro.pdfName
                                : "Document.pdf"
                            );
                          });
                      }
                    }}
                  >
                    {"Descargar "}
                    <div style={{ padding: "2%" }}>
                      <DownloadIcon style={{ margin: "4px" }} />
                    </div>
                  </button>
                </div>
              </div>

              <div className='flex-col' style={{ width: "70%" }}>
                {getDocument(loadingFlow, formSeguro!, formPdfSeguro)}
              </div>
            </div>
          )
        );
      case "CAMPOS":
        return (
          formSeguro && (
            <div
              className='flex-col primary-color-35'
              style={{ width: "100%", overflow: "auto", height: "250px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  background: "rgba(0,0,0,0.2)",
                  margin: "0 1% 1% 1%",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    width: "100%",
                    borderBottom: "1px rgba(255,255,255,0.4)",
                    background: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Información Solicitada
                  </div>
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Respuesta
                  </div>
                  <div
                    className='text-normal text-medium'
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Tipo de Campo
                  </div>
                </div>
                {formSeguro.requirements.map((e) => {
                  let response = formSeguro?.data[e.stitchTypeId];
                  return (
                    <div
                      key={response + e.stitchTypeId}
                      style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        width: "100%",
                        borderBottom: "1px rgba(255,255,255,0.4)",
                      }}
                    >
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {e.stitchTypeId}
                      </div>
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {response}
                      </div>
                      <div
                        className='text-normal text-x-small'
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        );
      default:
        return <></>;
    }
  };
  //--------------------------------GET CURRENT CONTENT ELECTRONIC_SIGNATURE-----------------------
  let getCurrentContentFirma = () => {
    switch (currentSectionFirma) {
      case "DOCUMENTOS":
        return (
          signature && (
            <div className='flex-row' style={{ height: "100%" }}>
              <div
                className='flex-col'
                style={{ width: "30%", padding: "0 0 10px 20px" }}
              >
                <div className='text-normal text-small primary-color-35'>
                  {` ID Documento: ${signature.documentId}`}
                </div>
                <div className='text-normal text-small primary-color-35'>
                  {` Estado actual: ${getStatusName(signature.status)}`}
                </div>
                {signature.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificado
                          ? "rgba(0,0,0,0.2)"
                          : "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificado(false);
                      }}
                    >
                      {"Documento Firmado"}
                    </button>
                  </div>
                )}
                {signature.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificado
                          ? "rgba(0,0,0,0.4)"
                          : "rgba(0,0,0,0.2)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificado(true);
                      }}
                    >
                      {"Certificado de Firma"}
                    </button>
                  </div>
                )}
                {signature.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (signatureDocs) {
                          let url = `data:application/pdf;base64,${
                            certificado
                              ? signatureDocs?.certificate.documentBase64
                              : signatureDocs?.document.documentBase64
                          }`;

                          fetch(url)
                            .then((res) => res.blob())
                            .then((blob) => {
                              fileDownload(
                                blob,
                                certificado
                                  ? "Certificado.pdf"
                                  : "Documento.pdf"
                              );
                            });
                        }
                      }}
                      className='flex-row text-small primary-color'
                      style={{
                        borderRadius: "8px",
                        background: "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                    >
                      {"Descargar "}
                      <div style={{ padding: "2%" }}>
                        <DownloadIcon style={{ cursor: "pointer" }} />
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {signature.status === "Signed" ? (
                <div className='flex-col' style={{ width: "70%" }}>
                  {signatureDocs &&
                    getDocumentSign(
                      loadingFlow,
                      certificado
                        ? signatureDocs.certificate
                        : signatureDocs.document
                    )}
                </div>
              ) : (
                <div
                  className='flex-col'
                  style={{
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ color: "white" }}>
                    El documento no ha sido firmado
                  </h5>
                </div>
              )}
            </div>
          )
        );
      case "FIRMANTES":
        return (
          signature && (
            <div
              className='flex-col primary-color-35'
              style={{ width: "100%", height: "100%" }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className='text-normal text-medium'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Nombre y Apellido</th>
                    <th>Fecha de la Firma</th>
                    <th>User ID</th>
                    <th>Estado</th>
                    <th>Método</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    fontSize: "13px",
                    height: "30px",
                  }}
                >
                  {signature &&
                    signature.signers.map((e) => {
                      return (
                        <tr className='list-hover'>
                          <td>{`${e.name} ${e.lastName}`}</td>
                          <td>{formatDateStandar(e.startedDate)}</td>
                          <td>{e.userId}</td>
                          <td>{e.status}</td>
                          <td>{e.validationMethod}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )
        );

      case "HISTORIAL DE ACCIONES":
        return (
          signature && (
            <div
              className='flex-col primary-color-35'
              style={{
                width: "100%",
                padding: "0 10px 0 10px",
                height: "100%",
              }}
            >
              <div className='text-normal text-medium primary-color'>
                Historial del Documento
              </div>
              <table
                style={{
                  margin: "1% 0 0 0",
                  width: "100%",

                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className='text-normal text-medium primary-color'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Acción</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    height: "30px",
                    fontSize: "12px",
                  }}
                >
                  {signature.history &&
                    signature.history.map((e) => {
                      return (
                        <tr className='list-hover'>
                          <td>{e.action}</td>
                          <td>{formatDateStandar(e.date)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  //-----------------------------GET CURRENT CONTENT ELECTRONIC_SIGNATURE SEGURO--------------------
  let getCurrentContentFirmaSeguro = () => {
    switch (currentSectionFirmaSeguro) {
      case "DOCUMENTOS":
        return (
          signatureSeguro && (
            <div className='flex-row' style={{ height: "100%" }}>
              <div
                className='flex-col'
                style={{ width: "30%", padding: "0 0 10px 20px" }}
              >
                <div className='text-normal text-small primary-color-35'>
                  {` ID Documento: ${signatureSeguro.documentId}`}
                </div>
                <div className='text-normal text-small primary-color-35'>
                  {` Estado actual: ${getStatusName(signatureSeguro.status)}`}
                </div>
                {signatureSeguro.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificadoSeguro
                          ? "rgba(0,0,0,0.2)"
                          : "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificadoSeguro(false);
                      }}
                    >
                      {"Documento Firmado"}
                    </button>
                  </div>
                )}
                {signatureSeguro.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificadoSeguro
                          ? "rgba(0,0,0,0.4)"
                          : "rgba(0,0,0,0.2)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificadoSeguro(true);
                      }}
                    >
                      {"Certificado de Firma"}
                    </button>
                  </div>
                )}
                {signatureSeguro.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (signatureDocs) {
                          let url = `data:application/pdf;base64,${
                            certificadoSeguro
                              ? signatureSeguroDocs?.certificate.documentBase64
                              : signatureSeguroDocs?.document.documentBase64
                          }`;

                          fetch(url)
                            .then((res) => res.blob())
                            .then((blob) => {
                              fileDownload(
                                blob,
                                certificadoSeguro
                                  ? "Certificado.pdf"
                                  : "Documento.pdf"
                              );
                            });
                        }
                      }}
                      className='flex-row text-small primary-color'
                      style={{
                        borderRadius: "8px",
                        background: "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                    >
                      {"Descargar "}
                      <div style={{ padding: "2%" }}>
                        <DownloadIcon style={{ cursor: "pointer" }} />
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {signatureSeguro.status === "Signed" ? (
                <div className='flex-col' style={{ width: "70%" }}>
                  {signatureSeguroDocs &&
                    getDocumentSign(
                      loadingFlow,
                      certificadoSeguro
                        ? signatureSeguroDocs.certificate
                        : signatureSeguroDocs.document
                    )}
                </div>
              ) : (
                <div
                  className='flex-col'
                  style={{
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ color: "white" }}>
                    El documento no ha sido firmado
                  </h5>
                </div>
              )}
            </div>
          )
        );
      case "FIRMANTES":
        return (
          signatureSeguro && (
            <div
              className='flex-col primary-color-35'
              style={{ width: "100%", height: "100%" }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className='text-normal text-medium'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Nombre y Apellido</th>
                    <th>Fecha de la Firma</th>
                    <th>User ID</th>
                    <th>Estado</th>
                    <th>Método</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    fontSize: "13px",
                    height: "30px",
                  }}
                >
                  {signatureSeguro?.signers.map((e) => {
                    return (
                      <tr className='list-hover' key={e.signerId}>
                        <td>{`${e.name} ${e.lastName}`}</td>
                        <td>{formatDateStandar(e.startedDate)}</td>
                        <td>{e.userId}</td>
                        <td>{e.status}</td>
                        <td>{e.validationMethod}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );

      case "HISTORIAL DE ACCIONES":
        return (
          signatureSeguro && (
            <div
              className='flex-col primary-color-35'
              style={{
                width: "100%",
                padding: "0 10px 0 10px",
                height: "100%",
              }}
            >
              <div className='text-normal text-medium primary-color'>
                Historial del Documento
              </div>
              <table
                style={{
                  margin: "1% 0 0 0",
                  width: "100%",

                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className='text-normal text-medium primary-color'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Acción</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    height: "30px",
                    fontSize: "12px",
                  }}
                >
                  {signatureSeguro.history?.map((e) => {
                    return (
                      <tr className='list-hover' key={e.action}>
                        <td>{e.action}</td>
                        <td>{formatDateStandar(e.date)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  let getErrorData = () => {
    let lst: JSX.Element[] = [];
    if (webhookData) {
      webhookData.errorDescriptions.forEach((errorDesc) => {
        lst.push(
          <div>
            <div>Error en el webhook: {errorDesc.url}</div>
            <div>Mensaje: {errorDesc.msg}</div>
          </div>
        );
      });
    }
    return lst;
  };

  let showAttatchments = (
    currentAttachments: Array<Attatchment>,
    loadingStatus: LoadingStatus,
    downloadStatus: LoadingStatus
  ) => {
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color='white' size='100px' />}
          />
        );

      case "rejected":
        return (
          <div
            className='flex-col text-medium primary-color'
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar adjuntos
          </div>
        );
      case "resolved":
        if (!currentAttachments || currentAttachments.length === 0) {
          return (
            <div
              className='flex-col text-normal text-medium primary-color'
              style={{
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Este registro no tiene adjuntos
            </div>
          );
        } else {
          return (
            <div
              className='flex-col'
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead
                  className='text-normal text-medium primary-color-35'
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre adjunto</th>
                    <th>Nombre archivo</th>
                    <th>Descargar</th>
                    <th>Visualizar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className='text-normal text-x-small primary-color'
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {currentAttachments?.map((attatchment, i) => {
                    return (
                      <tr key={i} style={{ height: "30px" }}>
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {attatchment.fieldName || ""}
                        </td>
                        <td>{attatchment.fileName || ""}</td>
                        <td>
                          {downloadStatus === "pending" &&
                          selectedDownload === i ? (
                            <LoadingOverlay
                              active={downloadStatus === "pending"}
                              spinner={<ClipLoader color='white' size='20px' />}
                            />
                          ) : (
                            <DownloadIcon
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                downloadAtatchment(attatchment, i);
                              }}
                            />
                          )}
                        </td>{" "}
                        <td>
                          {downloadStatus === "pending" &&
                          selectedDownload === i ? (
                            <LoadingOverlay
                              active={downloadStatus === "pending"}
                              spinner={<ClipLoader color='white' size='20px' />}
                            />
                          ) : (
                            <EyeIcon
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                downloadAtatchment(attatchment, i, true);
                              }}
                            />
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <button
                className='flex-col'
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  borderRadius: "10px",
                  background: "rgba(0,0,0,0.4)",
                  border: "none",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: 700,
                  marginTop: "20px",
                  padding: "10px 20px",
                  width: "fit-content",
                }}
                onClick={() => {
                  if (currentAttachments?.length > 0) {
                    dispatch(
                      getAttachmentsZip({
                        filledFormIds: getAttachmentIds(currentAttachments),
                        userId: currentFlow?.registration?.id! || "",
                      })
                    );
                  }
                }}
              >
                {downloadingAttatchmentZip === "pending" ? (
                  <ClipLoader color='white' size='30px' />
                ) : (
                  "Descargar todos los adjuntos"
                )}
              </button>
            </div>
          );
        }
      default:
        return (
          <div
            className='flex-col text-normal text-medium primary-color'
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Adjuntos
          </div>
        );
    }
  };

  const getAttachmentIds = (attachments: Array<Attatchment>) => {
    let ids: Array<string> = [];
    attachments.forEach((att) => {
      if (ids.includes(att.filledFormId)) return;
      ids.push(att.filledFormId);
    });
    return ids;
  };

  let downloadAtatchment = (
    attatchment: Attatchment,
    selected: number,
    visualized?: boolean
  ) => {
    setSelectedDownload(selected);
    dispatch(
      getAttatchmentData({
        filledFormId: attatchment.filledFormId,
        stitchTypeId: attatchment.stitchTypeId,
        visualized: visualized,
      })
    );
  };
  const getAttachmentRender = (mimeType: string) => {
    switch (mimeType) {
      case "application/pdf":
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width='100%'
            height='100%'
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
      case "image/png":
        return (
          <img src={attachmentToVisualize.url} width='100%' height='auto' />
        );
      case "image/jpeg":
        return (
          <img src={attachmentToVisualize.url} width='100%' height='auto' />
        );

      case "image/jpg":
        return (
          <img src={attachmentToVisualize.url} width='100%' height='auto' />
        );

      case "image/gif":
        return (
          <img src={attachmentToVisualize.url} width='100%' height='auto' />
        );

      case "video/mp4":
        return (
          <video width='100%' height='auto' controls>
            <source src={attachmentToVisualize.url} type='video/mp4' />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/ogg":
        return (
          <video width='100%' height='auto' controls>
            <source src={attachmentToVisualize.url} type='video/ogg' />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/mpeg":
        return (
          <video width='100%' height='auto' controls>
            <source src={attachmentToVisualize.url} type='video/ogg' />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/webm":
        return (
          <video width='100%' height='auto' controls>
            <source src={attachmentToVisualize.url} type='video/ogg' />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/x-msvideo":
        return (
          <video width='100%' height='auto' controls>
            <source src={attachmentToVisualize.url} type='video/ogg' />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      default:
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width='100%'
            height='100%'
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
    }
  };

  return (
    <LoadingOverlay
      active={loadingFlow === "pending"}
      spinner={<ClipLoader color='white' size='150px' />}
    >
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel='Error ejecutando WebHooks'
        shouldCloseOnOverlayClick={true}
      >
        <div
          className='flex-col'
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className='text-medium text-normal'
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            {getErrorData()}
          </div>
          <button
            className='text-medium text-bold primary-color'
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Intente nuevamente
          </button>
        </div>
      </Modal>
      {!currentFlow || currentFlow.flowId !== "" ? (
        <div
          className='flex-col'
          style={{
            height: "100%",
            width: "97%",
            overflow: "auto",
            boxSizing: "border-box",
            paddingLeft: "1%",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetFlow());
              navigate(-1);
            }}
            className='flex-row'
            style={{
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <LeftArrow style={{ marginRight: "15px" }} />
            <div className='text-bold text-x-large primary-color'>
              Regresar a lista de verificaciones
            </div>
          </div>
          <div className='flex-row'>
            <div style={{ margin: "10px 0 10px 0", width: "55%" }}>
              <div
                className='text-medium primary-color'
                style={{
                  background: "rgba(0,0,0,0.4)",
                  width: "95%",
                  padding: "3% 0 10px 3%",
                }}
              >
                {getRowInfoClient(
                  "Nombre y apellido:",
                  currentFlow?.registration && currentFlow.registration.name
                    ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                    : ""
                )}
                {getRowInfoClient(
                  "Id del flujo:",
                  currentFlow && currentFlow.id ? currentFlow.id : ""
                )}
                {getRowInfoClient(
                  "Email:",
                  currentFlow?.registration && currentFlow.registration.email
                    ? currentFlow.registration.email
                    : ""
                )}
                {getRowInfoClient(
                  "Nº Celular:",
                  currentFlow?.registration && currentFlow.registration.phone
                    ? currentFlow.registration.phone
                    : ""
                )}
                {getRowInfoClient(
                  "Fecha de registro:",
                  formatDateStandar(currentFlow?.registration?.startDatetime!)
                )}
                <div style={{ height: "10px" }}></div>
                {getRowInfoClient(
                  "Comentarios:",
                  currentFlow?.registration && currentFlow.comment
                    ? currentFlow.comment
                    : ""
                )}
              </div>
            </div>
            {roles.includes("smartflow.edit") && (
              <div
                className='col'
                style={{ margin: "10px", padding: "0% 1% 0% 1%" }}
              >
                <StateComponent
                  stepDescriptionId=''
                  eventChangeCustom={(data: {
                    comment: string;
                    state: string;
                  }) => {
                    console.log(data.state, data.comment, "change status");
                    dispatch(
                      changeStatusById({
                        comment: data.comment,
                        status: data.state,
                      })
                    );
                  }}
                  currentState={currentFlow ? currentFlow.status : ""}
                  stateOptions={{
                    PENDING: "Pendiente",
                    APPROVED: "Aprobado",
                    REJECTED: "Rechazado",
                  }}
                  updateRe={id === "flowsrevision" ? "" : id ? id : ""}
                />
              </div>
            )}
          </div>
          {currentFlow && loadingFlow !== "pending" && currentFlow.asesorId && (
            <div className='flex-row'>
              <div
                style={{
                  margin: "5px 0 10px 0",
                  width: "50%",
                }}
              >
                {getAsesorInfo(currentFlow.asesorId)}
              </div>
            </div>
          )}
          <div
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "20%",
                margin: " 0% 1% 0% 0%",
              }}
            >
              {getCurrentSectionMenu(currentSection ? currentSection : "")}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
                overflow: "auto",
              }}
            >
              <ExpandibleContent
                title='Core ID'
                isActive={currentSection === "CORE_ID"}
                onClickFunction={() => {
                  setCurrentSection("CORE_ID");
                }}
                content={
                  <>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className='flex-col text-normal text-medium primary-color'
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "5%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Este paso aún no se ha completado
                      </div>
                    ) : (
                      getCurrentSectionContent("CORE_ID")
                    )}
                  </>
                }
              />

              <ExpandibleContent
                title='Magic Forms'
                isActive={currentSection === "MAGIC_FORMS"}
                onClickFunction={() => {
                  setCurrentSection("MAGIC_FORMS");
                }}
                content={
                  <>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className='flex-col text-normal text-medium primary-color'
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "5%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Este paso aún no se ha completado
                      </div>
                    ) : (
                      <>{getCurrentSectionContent("MAGIC_FORMS")}</>
                    )}
                  </>
                }
              />
              <ExpandibleContent
                title='Magic Forms Seguro'
                isActive={currentSection === "MAGIC_FORMS_SEGURO"}
                onClickFunction={() => {
                  setCurrentSection("MAGIC_FORMS_SEGURO");
                }}
                content={
                  <>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className='flex-col text-normal text-medium primary-color'
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "5%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Este paso aún no se ha completado
                      </div>
                    ) : (
                      <>{getCurrentSectionContent("MAGIC_FORMS_SEGURO")}</>
                    )}
                  </>
                }
              />
              <ExpandibleContent
                title='Firma'
                isActive={currentSection === "ELECTRONIC_SIGNATURE"}
                onClickFunction={() => {
                  setCurrentSection("ELECTRONIC_SIGNATURE");
                }}
                content={
                  <>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className='flex-col text-normal text-medium primary-color'
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        El paso de firma electrónica aún no se ha completado
                      </div>
                    ) : (
                      <> {getCurrentSectionContent("ELECTRONIC_SIGNATURE")}</>
                    )}
                  </>
                }
              />
              <ExpandibleContent
                title='Firma Seguro'
                isActive={currentSection === "ELECTRONIC_SIGNATURE_SEGURO"}
                onClickFunction={() => {
                  setCurrentSection("ELECTRONIC_SIGNATURE_SEGURO");
                }}
                content={
                  <>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className='flex-col text-normal text-medium primary-color'
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        El paso de firma electrónica del seguro aún no se ha
                        completado
                      </div>
                    ) : (
                      <>
                        {" "}
                        {getCurrentSectionContent(
                          "ELECTRONIC_SIGNATURE_SEGURO"
                        )}
                      </>
                    )}
                  </>
                }
              />
              {currentFlow?.registration && (
                <ExpandibleContent
                  title='Adjuntos'
                  isActive={currentSection === "Adjuntos"}
                  contentHeight='50%'
                  onClickFunction={() => {
                    setCurrentSection("Adjuntos");
                  }}
                  content={
                    <div className='flex-col'>
                      <div
                        className='flex-col'
                        style={{
                          border: "1px solid rgba(0,0,0,0.2)",
                          boxSizing: "border-box",
                        }}
                      >
                        {showAttatchments(
                          currentAttachments,
                          loadingAttatchments,
                          downloadingAttatchment
                        )}
                      </div>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className='flex-col'
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className='text-huge text-bold primary-color'>
            {loadingFlow === "idle" ? "" : "No se encontró el flujo"}
          </h1>
        </div>
      )}
      {attachmentToVisualize.url && (
        <div
          className='flex-col'
          style={{
            position: "absolute",
            left: "1%",
            top: "5%",
            height: "90%",
            width: "97%",
          }}
        >
          <button
            onClick={() =>
              dispatch(
                setAttachmentToVisualize({
                  fileName: "",
                  mimeType: "",
                  url: "",
                })
              )
            }
            style={{
              display: "flex",
              flexDirection: "row",
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              fontSize: "25px",
              background: "#1A617D",

              width: "100%",
              color: "white",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 20px",
            }}
          >
            <p> </p> <p style={{ margin: 0 }}> Cerrar</p>
            <p style={{ margin: 0, fontSize: "30px", fontWeight: "bold" }}>X</p>
          </button>
          {getAttachmentRender(attachmentToVisualize.mimeType)}
        </div>
      )}
    </LoadingOverlay>
  );
};
