import { useEffect, useState } from "react";
import { FieldValidator } from "../../dtos/DrawableFormTemplate";
import { set } from "date-fns";

// const getConstantsView
type ValidatorEditorComponentProps = {
  validator: FieldValidator;
  onChange: (validator: FieldValidator) => void;
};
const ValidatorEditorComponent: React.FC<ValidatorEditorComponentProps> = ({
  validator,
  onChange,
}) => {
  const [localValidator, setLocalValidator] =
    useState<FieldValidator>(validator);
  const [localEdited, setLocalEdited] = useState(false);
  useEffect(() => {
    setLocalValidator(validator);
  }, [validator]);

  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0,0,0,0.2)",
        borderRadius: "10px",
        overflow: "auto",
      }}
    >
      <table>
        <thead
          className='text-normal text-small primary-color'
          style={{
            height: "30px",
            textAlign: "center",
          }}
        >
          <tr style={{ height: "15px" }}>
            <th style={{ width: "600px" }}>Campo</th>
            <th style={{ width: "600px" }}>Valor</th>
          </tr>
        </thead>
        <tbody
          className={`text-normal text-small primary-color-50`}
          style={{
            textAlign: "center",
            margin: "5px 0 5px 0",
          }}
        >
          {/* Mensaje */}
          <tr
            className=''
            style={{
              padding: "2px",
              //   flexDirection: "row",
              //   display: "flex",
              //   alignItems: "center",
            }}
          >
            <td>
              <h1 className='text-normal text-small primary-color-80'>
                Mensaje
              </h1>
            </td>
            <td>
              <textarea
                style={{ width: "600px" }}
                value={localValidator.message}
                onChange={(e) => {
                  setLocalEdited(true);
                  setLocalValidator({
                    ...localValidator,
                    message: e.target.value,
                  });
                }}
                className='text-normal text-small primary-color-80 input1'
              />
            </td>
          </tr>
        </tbody>
      </table>

      {localEdited && (
        <button
          className='boton1'
          style={{ marginTop: "20px" }}
          onClick={() => {
            onChange(localValidator);
          }}
        >
          Guardar Cambios
        </button>
      )}
    </div>
  );
};

export default ValidatorEditorComponent;
