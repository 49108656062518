import { useEffect, useState } from "react";
import { AuthentikatorData } from "../../../magicforms/magicFormsAPI";

// const getConstantsView
type AuthentikatorDataComponentProps = {
  data: AuthentikatorData | null;
};
const AuthentikatorDataComponent: React.FC<AuthentikatorDataComponentProps> = ({
  data,
}) => {
  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0, 0, 0, 0)",
        borderRadius: "10px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h1 className='text-huge text-white'>Authentikator</h1>
      </div>

      <h1
        className='text-medium'
        style={{
          color:
            data?.validityScore ?? 0 > 0.5 ? "#C7FF4F" : "rgb(255, 92, 92)",
        }}
      >
        Puntaje alteraciones: {data?.alterationsScore}
      </h1>
      <h1
        className='text-medium'
        style={{
          color:
            data?.validityScore ?? 0 > 0.5 ? "#C7FF4F" : "rgb(255, 92, 92)",
        }}
      >
        Puntaje validez: {data?.validityScore}
      </h1>
      <h1
        className='text-medium'
        style={{
          color:
            data?.validityScore ?? 0 > 0.5 ? "#C7FF4F" : "rgb(255, 92, 92)",
        }}
      >
        Incongruencias: 0
      </h1>
      <h1 className='text-medium text-white' style={{marginTop: "20px", textAlign: "center"}}>Datos extraidos:</h1>
      <table style={{marginTop: "25px"}}>
        <thead style={{padding: "5px"}}>
          <tr>
            <th>
              <h1 className='text-medium text-white'>Campo</h1>
            </th>
            <th>
              <h1 className='text-medium text-white'>Valor</h1>
            </th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>&nbsp;</td>
                <td></td>
            </tr>
          {data?.extractedData &&
            Object.keys(data.extractedData).map((key) => {
              return (
                <tr key={key} style={{padding: "5px"}}>
                  <td style={{padding: "5px"}}>
                    <h1 className='text-medium text-white' >{key}</h1>
                  </td>
                  <td style={{padding: "5px"}}>
                    <h1 className='text-medium text-white'>
                      {data.extractedData[key]}
                    </h1>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AuthentikatorDataComponent;
