import { useEffect, useState } from "react";
import "../MfEditorPage.scss";
import {
  DrawableFormTemplate,
  FormField,
  FormFieldTypeMapper,
  FormPage,
} from "../../dtos/DrawableFormTemplate";
import FieldsDetailComponent from "./FieldsDetailComponent";
import { log } from "console";
type DrawableTemplateEditorProps = {
  template: DrawableFormTemplate;
  onChange: (template: DrawableFormTemplate) => void;
};

const DrawableTemplateEditor: React.FC<DrawableTemplateEditorProps> = ({
  template,
  onChange,
}) => {
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [selectedFieldId, setSelectedFieldId] = useState<string>("");
  const [selectedParentFieldId, setSelectedParentFieldId] =
    useState<string>("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "center",
        height: "90%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.2)",
        padding: "20px",
        margin: "20px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "20vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              // justifyContent: "center",
              height: "100%",
              width: "20%",
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: "20px",
              borderRadius: "10px",
              overflow: "scroll",
            }}
          >
            <PagesComponent
              pages={template.formPages}
              selectPage={(index) => {
                setSelectedFieldId("");
                setSelectedParentFieldId("");
                setSelectedPage(index);
              }}
              selectedPage={selectedPage}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              height: "100%",
              width: "76%",
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: "20px",
              marginLeft: "2%",
              borderRadius: "10px",
            }}
          >
            <PageComponent
              page={template.formPages[selectedPage]}
              onChange={(page) => {
                onChange({
                  ...template,
                  formPages: template.formPages.map((p, index) =>
                    index === selectedPage ? page : p
                  ),
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
              height: "90%",
            }}
          >
            <FieldsComponent
              fields={template.formPages[selectedPage].fields}
              selectedFieldId={selectedFieldId}
              onSelectField={(id, parentFieldId) => {
                setSelectedFieldId(id);
                setSelectedParentFieldId(parentFieldId);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              height: "100%",
              padding: "20px",
            }}
          >
            {selectedFieldId !== "" && (
              <FieldsDetailComponent
                field={
                  selectedParentFieldId !== ""
                    ? template.formPages[selectedPage].fields[
                        selectedParentFieldId
                      ].subFields[selectedFieldId]
                    : template.formPages[selectedPage].fields[selectedFieldId]
                }
                parentFieldId={selectedParentFieldId}
                // field={template.formPages[selectedPage].fields[selectedFieldId]}
                subFieldId={selectedParentFieldId !== "" ? selectedFieldId : ""}
                id={selectedFieldId}
                onChange={(field, parentFieldId) => {
                  if (parentFieldId === "") {
                    onChange({
                      ...template,
                      formPages: template.formPages.map((p, index) =>
                        index === selectedPage
                          ? {
                              ...p,
                              fields: {
                                ...p.fields,
                                [selectedFieldId]: {
                                  ...p.fields[selectedFieldId],
                                  ...field,
                                },
                              },
                            }
                          : p
                      ),
                    });
                  } else {
                    onChange({
                      ...template,
                      formPages: template.formPages.map((p, index) =>
                        index === selectedPage
                          ? {
                              ...p,
                              fields: {
                                ...p.fields,
                                [parentFieldId]: {
                                  ...template.formPages[selectedPage].fields[
                                    parentFieldId
                                  ],
                                  subFields: {
                                    ...template.formPages[selectedPage].fields[
                                      parentFieldId
                                    ].subFields,
                                    [selectedFieldId]: {
                                      ...field,
                                    },
                                  },
                                },
                              },
                            }
                          : p
                      ),
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DrawableTemplateEditor;

//Const fiedsComponent
type FieldsComponentProps = {
  fields: { [key: string]: FormField };
  onSelectField: (id: string, parentFieldId: string) => void;
  selectedFieldId: string;
};

const FieldsComponent: React.FC<FieldsComponentProps> = ({
  fields,
  onSelectField,
  selectedFieldId,
}) => {
  type LocalField = {
    id: string;
    field: FormField;
  };
  const [localFields, setLocalFields] = useState<LocalField[]>([]);

  useEffect(() => {
    let newFields = Object.keys(fields).map((key) => {
      return {
        id: key,
        field: fields[key],
      };
    });
    newFields.sort((a, b) => {
      if (a.field.order.toString() > b.field.order.toString()) {
        return 1;
      } else {
        return -1;
      }
    });
    setLocalFields(newFields);
  }, [fields]);

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "65vh",
        overflow: "scroll",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <h1
        className='text-normal text-medium primary-color'
        style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px" }}
      >
        Campos
      </h1>
      {localFields.map((item) => (
        <div>
          <FieldComponent
            selected={item.id === selectedFieldId}
            key={item.id}
            id={item.id}
            field={item.field}
            onClick={(_) => {
              onSelectField(item.id, "");
            }}
          />
          {Object.keys(item.field.subFields).map((subFieldId, index) => {
            let subField = item.field.subFields[subFieldId];
            return (
              <FieldComponent
                selected={subField.stitchTypeId === selectedFieldId}
                key={subField.stitchTypeId}
                id={subField.stitchTypeId}
                field={subField}
                onClick={(_) => {
                  onSelectField(subField.stitchTypeId, item.id);
                }}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

const getFieldTypeName = (type: string): string => {
  return FormFieldTypeMapper[type];
};

//const fieldComponent
type FieldComponentProps = {
  id: string;
  field: FormField;
  onClick: (id: string) => void;
  selected: boolean;
};

const FieldComponent: React.FC<FieldComponentProps> = ({
  selected,
  field,
  onClick,
  id,
}) => {
  return (
    <div
      className={
        selected
          ? "containerFieldPreviewMasterSelected"
          : "containerFieldPreviewMaster"
      }
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        cursor: "pointer",
        // height: "100%",

        padding: "20px",
      }}
      onClick={() => onClick(id)}
    >
      <div className='editor-container-fieldPreview'>
        <h1 className='text-normal text-medium primary-color'>Título</h1>
        <input
          onClick={(event) => {
            event.preventDefault();
            onClick(id);
          }}
          style={{ height: "30px" }}
          type='text'
          disabled={true}
          contentEditable={false}
          value={field.title}
          className='text-normal text-small primary-color-80 input1'
          // onClick={(e) => {
          //   onClick(id);
          // }}
        />
      </div>
      <div className='editor-container-fieldPreview'>
        <h1 className='text-normal text-medium primary-color'>Id</h1>
        <input
          style={{ height: "30px" }}
          type='text'
          disabled={true}
          value={id}
          contentEditable={false}
          className='text-normal text-small primary-color-80 input1'
        />
      </div>
      <div className='editor-container-fieldPreview'>
        <h1 className='text-normal text-medium primary-color'>Tipo</h1>
        <input
          style={{ height: "30px" }}
          disabled={true}
          type='text'
          value={getFieldTypeName(field.type)}
          contentEditable={false}
          className='text-normal text-small primary-color-80 input1'
        />
      </div>
    </div>
  );
};

//const getPageComonent
type PageComponentProps = {
  page: FormPage;
  onChange: (page: FormPage) => void;
};
const PageComponent: React.FC<PageComponentProps> = ({ page, onChange }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div className='editor-container2'>
          <h1 className='text-normal text-medium primary-color'>Título</h1>
          <input
            type='text'
            value={page.title}
            onChange={(e) => {
              onChange({ ...page, title: e.target.value });
            }}
            className='text-normal text-small primary-color-80 input1'
          />
        </div>
        <div className='editor-container2'>
          <h1 className='text-normal text-medium primary-color'>
            Texto Botón siguiente
          </h1>
          <input
            type='text'
            value={page.continueButtonText}
            onChange={(e) => {
              onChange({ ...page, continueButtonText: e.target.value });
            }}
            className='text-normal text-small primary-color-80 input1'
          />
        </div>
        <div className='editor-container2'>
          <h1 className='text-normal text-medium primary-color'>
            Texto Botón atrás
          </h1>
          <input
            type='text'
            value={page.backButtonText}
            onChange={(e) => {
              onChange({ ...page, backButtonText: e.target.value });
            }}
            className='text-normal text-small primary-color-80 input1'
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div className='editor-container2'>
          <h1 className='text-normal text-medium primary-color'>
            Mostrar logo
          </h1>
          <input
            type='checkbox'
            style={{ width: "20px" }}
            checked={page.hasLogo}
            onChange={(e) => {
              onChange({ ...page, hasLogo: e.target.checked });
            }}
            className='text-normal text-small primary-color-80 input1'
          />
        </div>
        <div className='editor-container2'>
          <h1
            className='text-normal text-medium primary-color'
            style={{ width: "200px" }}
          >
            Lista de opciones de cancelación
          </h1>
          <button onClick={() => {}} className='boton1'>
            Editar
          </button>
        </div>
        <div className='editor-container2'>
          <h1 className='text-normal text-medium primary-color'>
            Nombre interno
          </h1>
          <input
            type='text'
            value={page.name ?? ""}
            onChange={(e) => {
              onChange({ ...page, name: e.target.value });
            }}
            className='text-normal text-small primary-color-80 input1'
          />
        </div>
      </div>
    </div>
  );
};

//const getPagesComponent
type PagesComponentProps = {
  pages: FormPage[];
  selectPage: (index: number) => void;
  selectedPage: number;
};

const PagesComponent: React.FC<PagesComponentProps> = ({
  pages,
  selectPage,
  selectedPage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        // justifyContent: "center",
        height: "100%",
      }}
    >
      <h1 className='text-normal text-medium primary-color'>
        Seleccionar página;
      </h1>
      {pages.map((page, index) => (
        <button
          style={{ marginTop: "10px" }}
          key={index}
          onClick={() => selectPage(index)}
          className={selectedPage === index ? "boton1Selected" : "boton1"}
        >
          {index}: {page.name ?? ""}
        </button>
      ))}
    </div>
  );
};
