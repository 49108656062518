import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import addMonths from "date-fns/addMonths";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  AlertsResult,
  BackofficeAsesor,
  CoreIdAnalyticsRequest,
  FirmaAnalyticsRequest,
  getAnalyticsCoreId,
  getAnalyticsFirmaAPI,
  getAnalyticsHyperflow,
  getAnalyticsMagicFormsAPI,
  getAnalyticsPagareAPI,
  getAnalyticsSmartflow,
  getAsesoresAPI,
  getStatusCoreIdByDateAPI,
  getStatusFirmaByDateAPI,
  getStatusHyperflowDateAPI,
  getStatusMagicFormsDateAPI,
  getStatusPagareByDateAPI,
  getStatusSmartflowDateAPI,
  HyperflowAnalyticsRequest,
  MagicFormsAnalyticsRequest,
  MFMAResult,
  PagareAnalyticsRequest,
  SmartFlowAnalyticsRequest,
  StatusResult,
  StatusResultByDate,
} from "./analyticsAPI";

export interface AnalyticsState {
  loadingMFMA: LoadingStatus;
  mfmaData: MFMAResult | null;
  loadingAlertsCoreId: LoadingStatus;
  alertsCoreId: AlertsResult | null;
  loadingStatusResult: LoadingStatus;
  statusResult: StatusResult[];
  coreIdAnalyticsRequest: CoreIdAnalyticsRequest;
  statusByDate: StatusResultByDate | null;
  hyperFlowRequest: HyperflowAnalyticsRequest;
  loadingStatusByDate: LoadingStatus;
  smartFlowRequest: SmartFlowAnalyticsRequest;
  magicFormsRequest: MagicFormsAnalyticsRequest;
  loadingMagicFormsAttachments: LoadingStatus;
  attachmentInfoMagicForms: {
    numAttachments: number;
    attachmentsAverage: number;
  };
  firmaRequest: FirmaAnalyticsRequest;
  loadingFirmaSignersInfo: LoadingStatus;
  signersInfo: {
    signers: number;
    averageSigners: number;
    totalSignatures: number;
  };
  pagareRequest: PagareAnalyticsRequest;
  generalPagareInfo: {
    diligenciados: number;
    endosados: number;
    firmados: number;
    bloqueadosPorEndoso: number;
    totalPagares: number;
  };
  loadingGeneralPagareInfo: LoadingStatus;
  loadingPagareType: LoadingStatus;
  asesores: BackofficeAsesor[];
  loadingAsesoresAnalytics: LoadingStatus;
  loadingAsesoresByDate: LoadingStatus;
  asesoresAnalytics: StatusResult[];
  asesoresByDate: StatusResultByDate | null;
}

const initialState: AnalyticsState = {
  loadingMFMA: "idle",
  loadingAlertsCoreId: "idle",
  loadingStatusResult: "idle",
  mfmaData: null,
  alertsCoreId: null,
  statusResult: [],
  statusByDate: null,
  loadingStatusByDate: "idle",
  coreIdAnalyticsRequest: {
    analyticsType: "status",
    endDate: new Date().toISOString(),
    projectId: null,
    result: null,
    startDate: addMonths(new Date(), -1).toISOString(),
  },
  hyperFlowRequest: {
    analyticsType: "status",
    hyperFlowId: null,
    status: null,
    endDate: new Date().toISOString(),
    startDate: addMonths(new Date(), -1).toISOString(),
    stepFilter: null,
  },
  smartFlowRequest: {
    analyticsType: "status",
    endDate: new Date().toISOString(),
    startDate: addMonths(new Date(), -1).toISOString(),
    status: null,
  },
  magicFormsRequest: {
    analyticsType: "status",
    endDate: new Date().toISOString(),
    startDate: addMonths(new Date(), -1).toISOString(),
    templateId: null,
  },
  loadingMagicFormsAttachments: "idle",
  attachmentInfoMagicForms: {
    attachmentsAverage: 0,
    numAttachments: 0,
  },
  firmaRequest: {
    analyticsType: "status",
    endDate: new Date().toISOString(),
    startDate: addMonths(new Date(), -1).toISOString(),
    status: null,
  },
  loadingFirmaSignersInfo: "idle",
  signersInfo: {
    averageSigners: 0,
    signers: 0,
    totalSignatures: 0,
  },
  generalPagareInfo: {
    bloqueadosPorEndoso: 0,
    diligenciados: 0,
    endosados: 0,
    firmados: 0,
    totalPagares: 0,
  },
  loadingGeneralPagareInfo: "idle",
  pagareRequest: {
    analyticsType: "status",
    endDate: new Date().toISOString(),
    startDate: addMonths(new Date(), -1).toISOString(),
    status: null,
    type: null,
  },
  loadingPagareType: "idle",
  asesores: [],
  loadingAsesoresAnalytics: "idle",
  loadingAsesoresByDate: "idle",
  asesoresAnalytics: [],
  asesoresByDate: null,
};

export const getMFMAData = createAsyncThunk(
  "analytics/getMFMAData",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentCoreIdRequest = { ...state.analytics.coreIdAnalyticsRequest };
    currentCoreIdRequest.analyticsType = "mfma";
    const analyticsResult = await getAnalyticsCoreId(currentCoreIdRequest);
    return analyticsResult.mfmaresult;
  }
);

export const getAlertsCoreId = createAsyncThunk(
  "analytics/getAlertsCoreId",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentCoreIdRequest = { ...state.analytics.coreIdAnalyticsRequest };
    currentCoreIdRequest.analyticsType = "alerts";
    const analyticsResult = await getAnalyticsCoreId(currentCoreIdRequest);
    return analyticsResult.alertsResult;
  }
);

export const getStatusCoreId = createAsyncThunk(
  "analytics/getStatusCoreId",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentCoreIdRequest = { ...state.analytics.coreIdAnalyticsRequest };
    currentCoreIdRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsCoreId(currentCoreIdRequest);
    return analyticsResult.statusResult;
  }
);

export const getStatusCoreIdByDate = createAsyncThunk(
  "analytics/getStatusCoreIdByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentCoreIdRequest = { ...state.analytics.coreIdAnalyticsRequest };
    currentCoreIdRequest.analyticsType = "status";
    const analyticsResult = await getStatusCoreIdByDateAPI(
      currentCoreIdRequest
    );
    return analyticsResult;
  }
);

export const getStatusHyperflow = createAsyncThunk(
  "analytics/getStatusHyperflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsHyperflow(currentRequest);
    return analyticsResult.statusResult;
  }
);

export const getAlertsHyperFlow = createAsyncThunk(
  "analytics/getAlertsHyperflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "alerts";
    const analyticsResult = await getAnalyticsHyperflow(currentRequest);
    return analyticsResult.alertsResult;
  }
);

export const getMFMAHyperflow = createAsyncThunk(
  "analytics/getMFMAHyperflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "mfma";
    const analyticsResult = await getAnalyticsHyperflow(currentRequest);
    return analyticsResult.mfmaresult;
  }
);

export const getStatusSmartflow = createAsyncThunk(
  "analytics/getStatusSmartflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.smartFlowRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsSmartflow(currentRequest);
    return analyticsResult.statusResult;
  }
);

export const getAlertsSmartflow = createAsyncThunk(
  "analytics/getAlertsSmartflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.smartFlowRequest };
    currentRequest.analyticsType = "alerts";
    const analyticsResult = await getAnalyticsSmartflow(currentRequest);
    return analyticsResult.alertsResult;
  }
);

export const getMFMASmartflow = createAsyncThunk(
  "analytics/getMFMASmartflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.smartFlowRequest };
    currentRequest.analyticsType = "mfma";
    const analyticsResult = await getAnalyticsSmartflow(currentRequest);
    return analyticsResult.mfmaresult;
  }
);

export const getAsesores = createAsyncThunk(
  "analytics/getAsesores",
  async (_, {}) => {
    let asesores = await getAsesoresAPI();
    return asesores;
  }
);

export const getAsesoresAnalyticsHyperflow = createAsyncThunk(
  "analytics/getAsesoresAnalyticsHyperflow",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "asesor";
    const analyticsResult = await getAnalyticsHyperflow(currentRequest);
    return analyticsResult.statusResult;
  }
);

export const getStatusHyperflowByDate = createAsyncThunk(
  "analytics/getStatusHyperflowByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getStatusHyperflowDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusSmartflowByDate = createAsyncThunk(
  "analytics/getStatusSmartflowByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.smartFlowRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getStatusSmartflowDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusHyperflowAsesoresByDate = createAsyncThunk(
  "analytics/getStatusHyperflowAsesoresByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.hyperFlowRequest };
    currentRequest.analyticsType = "asesor";
    const analyticsResult = await getStatusHyperflowDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusMagicForms = createAsyncThunk(
  "analytics/getStatusMagicForms",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.magicFormsRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsMagicFormsAPI(currentRequest);
    return analyticsResult.finished;
  }
);

export const getAttachmentInfoMagicForms = createAsyncThunk(
  "analytics/getAttachmentInfoMagicForms",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.magicFormsRequest };
    currentRequest.analyticsType = "attachments";
    const analyticsResult = await getAnalyticsMagicFormsAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusMagicformsByDate = createAsyncThunk(
  "analytics/getStatusMagicformsByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.magicFormsRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getStatusMagicFormsDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusFirma = createAsyncThunk(
  "analytics/getStatusFirma",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.firmaRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsFirmaAPI(currentRequest);
    return analyticsResult.statusResult;
  }
);

export const getSignersInfoFirma = createAsyncThunk(
  "analytics/getSignersInfoFirma",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.firmaRequest };
    currentRequest.analyticsType = "signers";
    const analyticsResult = await getAnalyticsFirmaAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusFirmaByDate = createAsyncThunk(
  "analytics/getStatusFirmaByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.firmaRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getStatusFirmaByDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusPagare = createAsyncThunk(
  "analytics/getStatusPagare",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.pagareRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getAnalyticsPagareAPI(currentRequest);
    return analyticsResult.statusResult;
  }
);

export const getGeneralInfoPagares = createAsyncThunk(
  "analytics/getGeneralInfoPagares",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.pagareRequest };
    currentRequest.analyticsType = "general";
    const analyticsResult = await getAnalyticsPagareAPI(currentRequest);
    return analyticsResult;
  }
);

export const getStatusPagareByDate = createAsyncThunk(
  "analytics/getStatusPagareByDate",
  async (_, { getState }) => {
    let state = getState() as RootState;
    let currentRequest = { ...state.analytics.pagareRequest };
    currentRequest.analyticsType = "status";
    const analyticsResult = await getStatusPagareByDateAPI(currentRequest);
    return analyticsResult;
  }
);

export const analyticsSlice = createSlice({
  name: "analyticsSlice",
  initialState,
  reducers: {
    changeCurrentCoreIdFilters: (
      state,
      action: PayloadAction<CoreIdAnalyticsRequest>
    ) => {
      state.loadingAlertsCoreId = "idle";
      state.loadingMFMA = "idle";
      state.loadingStatusResult = "idle";
      state.loadingStatusByDate = "idle";
      state.coreIdAnalyticsRequest = { ...action.payload };
    },
    changeHyperflowFilters: (
      state,
      action: PayloadAction<HyperflowAnalyticsRequest>
    ) => {
      state.loadingAlertsCoreId = "idle";
      state.loadingMFMA = "idle";
      state.loadingStatusResult = "idle";
      state.loadingStatusByDate = "idle";
      state.loadingAsesoresAnalytics = "idle";
      state.loadingAsesoresByDate = "idle";
      state.hyperFlowRequest = { ...action.payload };
    },
    changeSmartflowFilters: (
      state,
      action: PayloadAction<SmartFlowAnalyticsRequest>
    ) => {
      state.loadingAlertsCoreId = "idle";
      state.loadingMFMA = "idle";
      state.loadingStatusResult = "idle";
      state.loadingStatusByDate = "idle";
      state.smartFlowRequest = { ...action.payload };
    },
    changeMagicformsFilters: (
      state,
      action: PayloadAction<MagicFormsAnalyticsRequest>
    ) => {
      state.loadingStatusByDate = "idle";
      state.loadingStatusResult = "idle";
      state.loadingMagicFormsAttachments = "idle";
      state.magicFormsRequest = { ...action.payload };
    },
    changeFirmaFilters: (
      state,
      action: PayloadAction<FirmaAnalyticsRequest>
    ) => {
      state.loadingStatusByDate = "idle";
      state.loadingStatusResult = "idle";
      state.loadingFirmaSignersInfo = "idle";
      state.firmaRequest = { ...action.payload };
    },
    changePagareFilters: (
      state,
      action: PayloadAction<PagareAnalyticsRequest>
    ) => {
      state.loadingStatusByDate = "idle";
      state.loadingStatusResult = "idle";
      state.loadingGeneralPagareInfo = "idle";
      state.pagareRequest = { ...action.payload };
    },
    resetStateAnalytics: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // COREID
    builder
      .addCase(getMFMAData.fulfilled, (state, action) => {
        state.loadingMFMA = "resolved";
        state.mfmaData = action.payload;
      })
      .addCase(getMFMAData.pending, (state) => {
        state.loadingMFMA = "pending";
      });

    builder
      .addCase(getAlertsCoreId.fulfilled, (state, action) => {
        state.loadingAlertsCoreId = "resolved";
        state.alertsCoreId = action.payload;
      })
      .addCase(getAlertsCoreId.pending, (state) => {
        state.loadingAlertsCoreId = "pending";
      });

    builder
      .addCase(getStatusCoreId.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusCoreId.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusCoreIdByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusCoreIdByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });
    // Hyperflow
    builder
      .addCase(getStatusHyperflow.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusHyperflow.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusHyperflowByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusHyperflowByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });

    builder
      .addCase(getMFMAHyperflow.fulfilled, (state, action) => {
        state.loadingMFMA = "resolved";
        state.mfmaData = action.payload;
      })
      .addCase(getMFMAHyperflow.pending, (state) => {
        state.loadingMFMA = "pending";
      });
    builder
      .addCase(getAlertsHyperFlow.fulfilled, (state, action) => {
        state.loadingAlertsCoreId = "resolved";
        state.alertsCoreId = action.payload;
      })
      .addCase(getAlertsHyperFlow.pending, (state) => {
        state.loadingAlertsCoreId = "pending";
      });

    builder.addCase(getAsesores.fulfilled, (state, action) => {
      state.asesores = action.payload;
    });

    builder.addCase(
      getAsesoresAnalyticsHyperflow.fulfilled,
      (state, action) => {
        state.loadingAsesoresAnalytics = "resolved";
        state.asesoresAnalytics = action.payload;
      }
    );

    builder.addCase(getAsesoresAnalyticsHyperflow.pending, (state) => {
      state.loadingAsesoresAnalytics = "pending";
    });

    builder.addCase(
      getStatusHyperflowAsesoresByDate.fulfilled,
      (state, action) => {
        state.loadingAsesoresByDate = "resolved";
        state.asesoresByDate = action.payload;
      }
    );

    builder.addCase(getStatusHyperflowAsesoresByDate.pending, (state) => {
      state.loadingAsesoresByDate = "pending";
    });

    // Smartflow
    builder
      .addCase(getStatusSmartflow.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusSmartflow.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusSmartflowByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusSmartflowByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });

    builder
      .addCase(getMFMASmartflow.fulfilled, (state, action) => {
        state.loadingMFMA = "resolved";
        state.mfmaData = action.payload;
      })
      .addCase(getMFMASmartflow.pending, (state) => {
        state.loadingMFMA = "pending";
      });
    builder
      .addCase(getAlertsSmartflow.fulfilled, (state, action) => {
        state.loadingAlertsCoreId = "resolved";
        state.alertsCoreId = action.payload;
      })
      .addCase(getAlertsSmartflow.pending, (state) => {
        state.loadingAlertsCoreId = "pending";
      });

    // Magic Forms
    builder
      .addCase(getStatusMagicForms.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusMagicForms.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusMagicformsByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusMagicformsByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });
    builder
      .addCase(getAttachmentInfoMagicForms.fulfilled, (state, action) => {
        state.loadingMagicFormsAttachments = "resolved";
        state.attachmentInfoMagicForms = action.payload;
      })
      .addCase(getAttachmentInfoMagicForms.pending, (state) => {
        state.loadingMagicFormsAttachments = "pending";
      });

    // Firma electrónica

    builder
      .addCase(getStatusFirma.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusFirma.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusFirmaByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusFirmaByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });
    builder
      .addCase(getSignersInfoFirma.fulfilled, (state, action) => {
        state.loadingFirmaSignersInfo = "resolved";
        state.signersInfo = {
          averageSigners: action.payload.averageSigners,
          signers: action.payload.signers,
          totalSignatures: action.payload.totalSignatures,
        };
      })
      .addCase(getSignersInfoFirma.pending, (state) => {
        state.loadingFirmaSignersInfo = "pending";
      });

    // Pagares
    builder
      .addCase(getStatusPagare.fulfilled, (state, action) => {
        state.loadingStatusResult = "resolved";
        state.statusResult = action.payload;
      })
      .addCase(getStatusPagare.pending, (state) => {
        state.loadingStatusResult = "pending";
      });
    builder
      .addCase(getStatusPagareByDate.fulfilled, (state, action) => {
        state.loadingStatusByDate = "resolved";
        state.statusByDate = action.payload;
      })
      .addCase(getStatusPagareByDate.pending, (state) => {
        state.loadingStatusByDate = "pending";
      });
    builder
      .addCase(getGeneralInfoPagares.fulfilled, (state, action) => {
        state.loadingGeneralPagareInfo = "resolved";
        state.generalPagareInfo = {
          bloqueadosPorEndoso: action.payload.bloqueadosPorEndoso,
          diligenciados: action.payload.diligenciados,
          endosados: action.payload.endosados,
          firmados: action.payload.firmados,
          totalPagares: action.payload.totalPagares,
        };
      })
      .addCase(getGeneralInfoPagares.pending, (state) => {
        state.loadingGeneralPagareInfo = "pending";
      });
  },
});

export const {
  changeCurrentCoreIdFilters,
  changeHyperflowFilters,
  resetStateAnalytics,
  changeMagicformsFilters,
  changeFirmaFilters,
  changePagareFilters,
  changeSmartflowFilters,
} = analyticsSlice.actions;

export const selectLoadingMFMA = (state: RootState) =>
  state.analytics.loadingMFMA;
export const selectMFMAData = (state: RootState) => state.analytics.mfmaData;
export const selectAnalyticsAlerts = (state: RootState) =>
  state.analytics.alertsCoreId;
export const selectLoadingAnalyticsAlerts = (state: RootState) =>
  state.analytics.loadingAlertsCoreId;
export const selectAnalyticsCoreIdRequest = (state: RootState) =>
  state.analytics.coreIdAnalyticsRequest;
export const selectLoadingStatusResult = (state: RootState) =>
  state.analytics.loadingStatusResult;
export const selectStatusResult = (state: RootState) =>
  state.analytics.statusResult;
export const selectLoadingStatusByDate = (state: RootState) =>
  state.analytics.loadingStatusByDate;
export const selectStatusByDate = (state: RootState) =>
  state.analytics.statusByDate;
export const selectHyperflowRequest = (state: RootState) =>
  state.analytics.hyperFlowRequest;
export const selectAnalyticsMagicFormsRequest = (state: RootState) =>
  state.analytics.magicFormsRequest;
export const selectAttachmentInfoMF = (state: RootState) =>
  state.analytics.attachmentInfoMagicForms;
export const selectLoadingMagicFormsAttachments = (state: RootState) =>
  state.analytics.loadingMagicFormsAttachments;
export const selectAnalyticsFirmaRequest = (state: RootState) =>
  state.analytics.firmaRequest;
export const selectSignersInfo = (state: RootState) =>
  state.analytics.signersInfo;
export const selectLoadingSignersInfo = (state: RootState) =>
  state.analytics.loadingFirmaSignersInfo;
export const selectAnalyticsPagareRequest = (state: RootState) =>
  state.analytics.pagareRequest;
export const selectLoadingGeneralInfoPagare = (state: RootState) =>
  state.analytics.loadingGeneralPagareInfo;
export const selectGeneralPagareInfo = (state: RootState) =>
  state.analytics.generalPagareInfo;
export const selectAsesores = (state: RootState) => state.analytics.asesores;
export const selectLoadingAsesoresAnalytics = (state: RootState) =>
  state.analytics.loadingAsesoresAnalytics;
export const selectAsesoresAnalytics = (state: RootState) =>
  state.analytics.asesoresAnalytics;
export const selectLoadingAsesoresByDate = (state: RootState) =>
  state.analytics.loadingAsesoresByDate;
export const selectAsesoresByDate = (state: RootState) =>
  state.analytics.asesoresByDate;
export const selectSmartFlowRequest = (state: RootState) =>
  state.analytics.smartFlowRequest;

export default analyticsSlice.reducer;
